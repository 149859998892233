import Billing from "views/billing";


const Routes = [
    {
        path: '/billing/:id?',
        view: Billing,
        layout: 'app',
        permission: 'admin',
        title: 'Billing'
    },
]



export default Routes;