import PropTypes from "prop-types";
import { TagElement } from './TagElement'


/**
 * Element component for rendering different types of nodes in the Slate editor.
 * This component handles rendering paragraphs and custom mention nodes.
 *
 * @param {Object} props - Properties passed to the Element component.
 * @param {Object} props.attributes - Attributes to apply to the component's root element.
 * @param {Object} props.children - Child nodes of the Element component.
 * @param {Object} props.element - Data of the current element being rendered.
 * @param {Object} props.editorRef - Reference to the Slate editor instance.
 * @returns {JSX.Element} A rendered element for the Slate editor.
 */
export const Element = ({ attributes, children, element, editorRef }) => {
    switch (element.type) {
        case 'mention':
            return <TagElement {...{ attributes, children, element, editorRef }} />
        default:
            return <p {...attributes}>{children}</p>
    }
}

Element.propTypes = {
    attributes: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    element: PropTypes.object.isRequired,
    editorRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.any })
    ]).isRequired,
};
