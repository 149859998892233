/***
*
*   AUTHENTICATION
*   Auth provider to manage auth functions throughout
*   the application. <PrivateRoute> component to
*   protect internal application routes from unauthenticated
*   access.
*
**********/

import { useState, useEffect, createContext, useContext } from 'react';
import axios from 'axios';
import { Navigate } from 'react-router-dom';

export const AuthContext = createContext();

const useAPI = require('components/lib').useAPI;
const Event = require('components/lib').Event;
const permissions = require('./permissions');

export function AuthProvider(props) {

  const cache = JSON.parse(localStorage.getItem('user')) || {}
  const [user, setUser] = useState(cache);
  const auth = useAPI(user?.id ? '/api/auth' : null)
  const redirectTo = sessionStorage.getItem('redirect_to')

  useEffect(() => {
    // update the auth status
    if (!auth.loading && auth.data) {
      auth.data.authenticated ? update(auth.data) : signout();
    }
  }, [auth.data]);

  function signin(res) {
    if (res.data) {
      localStorage.setItem('user', JSON.stringify(res.data));

      axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.token;
      Event('signin');

      if (!res.data.verified)
        return window.location.replace('/signup/verify');

      if (!res.data.business_verified)
        return window.location.replace('/signup/verify_business')

      sessionStorage.removeItem('redirect_to')

      if (redirectTo) {
        return window.location.replace(redirectTo)
      }
      return window.location.replace('/campaigns')
    }
  }

  async function signout() {
      axios({ method: 'delete', url: '/api/auth' });
      localStorage.clear();
      return window.location = '/signin'
  }

  async function switchAccount(id) {

    const res = await axios({

      method: 'post',
      url: '/api/auth/switch',
      data: { account: id }

    });

    if (res.data)
      signin(res)

  }

  function update(data) {
    if (localStorage.getItem('user')) {

      let user = JSON.parse(localStorage.getItem('user'));
      for (let key in data) {
        if (Array.isArray(data[key])) {
          user[key] = data[key]
        }
        else if (typeof data[key] === 'object') {
          for (let innerKey in data[key]) {
            user[key][innerKey] = data[key][innerKey]
          }
        }
        else {
          user[key] = data[key];
        }
      }

      localStorage.setItem('user', JSON.stringify(user));
      setUser(user);
    }
  }

  return (
    <AuthContext.Provider value={{
      user: user,
      signin: signin,
      signout: signout,
      update: update,
      switchAccount: switchAccount,
      permission: permissions[user?.permission]
    }}

      {...props} />
  );
}

// custom route object checks for an auth token before
// rendering the route – redirects if token is not present
export function PrivateRoute(props) {
  const { user } = useContext(AuthContext)
  const path = window.location.pathname;
  const redirectTo = sessionStorage.getItem('redirect_to')

  // check user exists
  if (user && user?.token) {
    //check permissions and redirect based on user permissions
    if (permissions[user?.permission][props.permission] || user?.scope?.includes(props.scope)) {
      if (user.verified && !user.business_verified) {
        if (path !== '/account/profile' && path !== '/signup/verify_business')
          return <Navigate to='/signup/verify_business' />;
      }

      if (user.verified && path === '/signup/verify') {
        return <Navigate to={redirectTo || '/campaigns'} />
      }

      if (!user.verified) {
        // user is not verified
        if (path !== '/account/profile' && path !== '/signup/verify')
          return <Navigate to='/signup/verify' />;
      }

      if (user.business_verified && path === '/signup/verify_business') {
        sessionStorage.removeItem('redirect_to')
        return <Navigate to={redirectTo || '/campaigns'} />
      }

      // user is good
      return props.children;
    }

    return <Navigate to='/' />;
  }

  sessionStorage.setItem('redirect_to', path)

  // user is not authenticated
  return <Navigate to='/signin' />;
}
