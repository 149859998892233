/***
 *
 *   SIGN UP STEP 1
 *   Signup form for account owners
 *   Step 1: create account
 *   Step 2: verify email address
 *   Step 3: verify business
 *
 **********/

import React, {useContext, useEffect, useState} from 'react';
import {
    AuthContext,
    Animate,
    Row,
    Card,
    SocialSignin,
    Form,
    Link,
    Button,
    TextInput,
    Message,
    EmailInput,
    PhoneInput,
    Loader,
    useView,
    useSocket,
    ModalContainer
} from 'components/lib';
import {useMutation} from '@tanstack/react-query';
import axios from 'axios';
import {LoadScript} from '@react-google-maps/api';
import {MAPS_API_KEY} from 'lib/googlemaps';
import {capitalizeFirstLetter} from 'helpers';
import moment from 'moment-timezone'
import {useRef} from 'react';

const libraries = ['places']

export function Signup() {
    return (
        <LoadScript loadingElement={<Loader/>} googleMapsApiKey={MAPS_API_KEY} libraries={libraries} language='en'>
            <SignupInner/>
        </LoadScript>
    )
}

function SignupInner(props) {
    const context = useContext(AuthContext);

    const [step, setStep] = useState(1)
    const [data, setData] = useState({
        email: '',
        firstname: '',
        lastname: '',
        category: '',
        phonenumber: '',
        business_lookup: ''
    })
    const [notSupported, setNotSupported] = useState(false)
    const [countryNot, setCountryNot] = useState(false)
    const [form, setForm] = useState({})
    const [valids, setValids] = useState([])

    const handleInputChange = (name, value, valid) => {
        if (name === 'firstname' || name === 'lastname') value = capitalizeFirstLetter(value)

        setData({...data, [name]: value})

        if (!valid) {
            setValids(prev => [...prev, name])
        } else {
            setValids(prev => prev.filter(p => p !== name))
        }
    }

    const nextStepMutation = useMutation({
        mutationFn: () => axios.post('/api/account/verify_supported_category', data)
    })

    const handleNextStep = async () => {
        if (valids.length || Object.values(data).some(d => !d)) return
        try {
            const res = await nextStepMutation.mutateAsync()
            const fdata = res?.data?.data
            if (fdata?.countryNot) {
                return setCountryNot(true)
            }
            if (!fdata?.supported) {
                return setNotSupported(true)
            }

            // localStorage.setItem('signup', JSON.stringify({
            //     business_lookup: fdata?.form?.business_lookup,
            //     firstname: data.firstname,
            //     lastname: data.lastname,
            //     phonenumber: data.phonenumber,
            //     timezone: moment.tz.guess(),
            // }))
            setForm(fdata?.form)
            setStep(2)
            return
        } catch (e) {
        }
    }

    useEffect(() => {
        const maps = window?.google?.maps
        if (!maps) return

        const businessLookupInput = document.getElementById('business_lookup');
        const autocomplete = new maps.places.Autocomplete(businessLookupInput);

        const placeChanged = () => {
            const place = autocomplete.getPlace();
            if (!place?.place_id) return;

            const service = new maps.places.PlacesService(businessLookupInput);

            service.getDetails({placeId: place.place_id, fields: ['type']}, async (result, status) => {
                if (status !== maps.places.PlacesServiceStatus.OK) return;

                setData(prev => ({...prev, category: result.types?.[0], business_lookup: businessLookupInput.value}))
            })
        }

        autocomplete.addListener('place_changed', placeChanged)


        return () => {
            window.google.maps.event.clearInstanceListeners(autocomplete)
        }
    }, [window.google])

    const getValid = (name) => {
        return valids.length && !valids.includes(name)
    }

    const view = useView()

    const attRef = useRef(null)
    const [att, setAtt] = useState(null)
    const [modal, setModal] = useState(false)
    const manualVMutation = useMutation({
        mutationKey: ['account', 'manual_verification_off'],
        mutationFn: (data) => axios.post('/api/account/manual_verification_off', data).then(res => res.data),
        onError: (e) => view.handleError(e)
    })

    const sizeError = att ? (att.size > 15 * 1024 * 1024) : false

    return (
        <Animate type='pop' key={step}>
            <Row title={step === 1 ? 'Get Started' : 'Create Your Account'}>
                <Card loading={false} className='max-w-xl' center>

                    {
                        (notSupported || countryNot) ?
                            <>
                                {
                                    notSupported && <Message
                                        title={`Business not supported`}
                                        type='error'
                                        text="We regret to inform you that our services currently do not extend to your business category. Rest assured, we will notify you via email as soon as we have tailored our services to cater to your type of business, Should you require additional help or information, please don't hestiate to contact our support team at Support@enzak.com"
                                    />
                                }
                                {
                                    countryNot && <Message
                                        title={`Country not supported`}
                                        type='error'
                                        text="We are currently providing service to US and Canada customers only at this time. We will keep your information on file and send you an email when we expand in to your country. Thank you for your consideration."
                                    />
                                }
                            </>
                            :
                            step === 1 && (
                                <div>
                                    <div className='sm:grid grid-cols-2 gap-4'>
                                        <TextInput
                                            label='First Name'
                                            name='firstname'
                                            required
                                            value={data.firstname}
                                            onChange={handleInputChange}
                                            valid={getValid('firstname')}
                                        />
                                        <TextInput
                                            label='Last Name'
                                            name='lastname'
                                            required
                                            value={data.lastname}
                                            onChange={handleInputChange}
                                            valid={getValid('lastname')}
                                        />
                                        <EmailInput
                                            label='Email'
                                            name='email'
                                            required
                                            value={data.email}
                                            onChange={handleInputChange}
                                            valid={getValid('email')}
                                        />
                                        <PhoneInput
                                            label='Personal Mobile Number'
                                            name='phonenumber'
                                            required
                                            value={data.phonenumber}
                                            onChange={handleInputChange}
                                            valid={getValid('phonenumber')}
                                        />
                                        <div className='col-span-2'>
                                            <TextInput
                                                id='business_lookup'
                                                label='Google Business Lookup:'
                                                name='business_lookup'
                                                placeholder='Lookup your business name or address'
                                                required
                                                value={data.business_lookup}
                                                onChange={handleInputChange}
                                                valid={getValid('business_lookup')}
                                            />
                                        </div>
                                    </div>

                                    <div className='mt-6'>
                                        <p>Can't find your business?</p>
                                        <button
                                            className='text-blue-400 underline'
                                            onClick={() => setModal(true)}
                                        >
                                            Click here to send us your business details and get varified.
                                        </button>
                                        {
                                            modal && (
                                                <ModalContainer close={() => setModal(false)}>
                                                    <Card title={`Can't find your business?`}>
                                                        <>
                                                            {
                                                                manualVMutation.isSuccess ? (
                                                                    <div>
                                                                        <p>
                                                                            Thank you! Your request has been sent to our support
                                                                            team. We
                                                                            will reach out to you via email within 24 to 48
                                                                            hours to
                                                                            manually verify your account. Thank you for your
                                                                            patience.
                                                                        </p>
                                                                        <Button
                                                                            className='!w-full'
                                                                            text='Close'
                                                                            color='green'
                                                                            action={() => setModal(false)}
                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        <p>
                                                                            If you're unable to receive your verification code
                                                                            due to an IVR
                                                                            or any other issue, please fill out the form below,
                                                                            and we will
                                                                            manually verify your account.
                                                                        </p>
                                                                        <div className='sm:grid grid-cols-2 gap-x-2'>
                                                                            <TextInput
                                                                                label='First Name'
                                                                                name='firstname'
                                                                                required
                                                                                value={data.firstname}
                                                                                onChange={handleInputChange}
                                                                                valid={getValid('firstname')}
                                                                            />
                                                                            <TextInput
                                                                                label='Last Name'
                                                                                name='lastname'
                                                                                required
                                                                                value={data.lastname}
                                                                                onChange={handleInputChange}
                                                                                valid={getValid('lastname')}
                                                                            />
                                                                            <EmailInput
                                                                                label='Email'
                                                                                name='email'
                                                                                required
                                                                                value={data.email}
                                                                                onChange={handleInputChange}
                                                                                valid={getValid('email')}
                                                                            />
                                                                            <PhoneInput
                                                                                label='Personal Mobile Number'
                                                                                name='phonenumber'
                                                                                required
                                                                                value={data.phonenumber}
                                                                                onChange={handleInputChange}
                                                                                valid={getValid('phonenumber')}
                                                                            />
                                                                            <TextInput
                                                                                label='Business Name'
                                                                                name='business_name'
                                                                                required
                                                                                value={data.business_name}
                                                                                onChange={handleInputChange}
                                                                                valid={getValid('business_name')}
                                                                            />
                                                                            <PhoneInput
                                                                                label='Business phone number'
                                                                                name='business_phonenumber'
                                                                                required
                                                                                value={data.business_phonenumber}
                                                                                onChange={handleInputChange}
                                                                                valid={getValid('business_phonenumber')}
                                                                            />
                                                                            <div className='col-span-2 mb-4'>
                                                                                <TextInput
                                                                                    label='Business Address'
                                                                                    name='business_address'
                                                                                    required
                                                                                    value={data.business_address}
                                                                                    onChange={handleInputChange}
                                                                                    valid={getValid('business_address')}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <TextInput
                                                                            name='desc'
                                                                            type={'textarea'}
                                                                            placeholder={"Anything else we need to know about your business or any comments."}
                                                                            value={data.desc}
                                                                            onChange={handleInputChange}
                                                                        />
                                                                        <div className='mb-6'>
                                                                            <p className='text-sm'>
                                                                                To help us expedite the verification process,
                                                                                you can also
                                                                                upload a photo of your business card, front of
                                                                                your
                                                                                business, letterhead, or any other information
                                                                                that can help
                                                                                us verify your business.
                                                                            </p>
                                                                            <input
                                                                                name='att'
                                                                                ref={attRef}
                                                                                type='file'
                                                                                className='hidden'
                                                                                accept=".jpeg, .jpg, .png, .heic, .gif, .bmp, .tiff, .tif, .webp, .pdf"
                                                                                onChange={(e) => setAtt(e.target.files[0])}
                                                                            />
                                                                            <button
                                                                                type='button'
                                                                                onClick={() => attRef.current.click()}
                                                                                className={`!w-full h-12 border border-dashed ${sizeError ? 'border-red-500 text-red-500' : 'border-blue-300'} text-blue-600 rounded text-center`}
                                                                            >
                                                                                {att ? att.name : 'Upload Attachment'}
                                                                            </button>
                                                                            {
                                                                                sizeError && (
                                                                                    <p className={'text-sm text-red-500'}>File size
                                                                                        exceeds 5MB
                                                                                        limit.</p>
                                                                                )
                                                                            }
                                                                        </div>
                                                                        <Button
                                                                            className='!w-full'
                                                                            type='button'
                                                                            text='Submit Request'
                                                                            loading={manualVMutation.isLoading}
                                                                            disabled={sizeError || valids.length || Object.values({
                                                                                firstname: data.firstname,
                                                                                lastname: data.lastname,
                                                                                email: data.email,
                                                                                phonenumber: data.phonenumber
                                                                            }).some(d => !d) || nextStepMutation.isLoading}
                                                                            action={() => {
                                                                                if (sizeError) return
                                                                                const formData = new FormData()
                                                                                formData.set('firstname', data.firstname)
                                                                                formData.set('lastname', data.lastname)
                                                                                formData.set('email', data.email)
                                                                                formData.set('phonenumber', data.phonenumber)
                                                                                formData.set('desc', data.desc)
                                                                                formData.set('business_name', data.business_name)
                                                                                formData.set('business_phonenumber', data.business_phonenumber)
                                                                                formData.set('business_address', data.business_address)
                                                                                formData.set('att', att)
                                                                                manualVMutation.mutate(formData)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                )
                                                            }
                                                        </>
                                                    </Card>
                                                </ModalContainer>
                                            )
                                        }
                                    </div>


                                    <Button
                                        text='Next Step'
                                        color='blue'
                                        className='mt-4 flex justify-center w-full'
                                        disabled={valids.length || Object.values(data).some(d => !d) || nextStepMutation.isLoading}
                                        action={handleNextStep}
                                        loading={nextStepMutation.isLoading}
                                    />
                                </div>
                            )
                    }

                    {
                        step === 2 && (
                            <>
                                <SocialSignin
                                    network={['google']}
                                    showOr
                                    signup
                                    qs={new URLSearchParams(data).toString()}
                                />
                                <Form
                                    inputs={{
                                        firstname: {
                                            label: 'First Name',
                                            type: 'text',
                                            required: true,
                                            errorMessage: 'Please enter your first name',
                                            value: form?.firstname,
                                        },
                                        lastname: {
                                            label: 'Last Name',
                                            type: 'text',
                                            required: true,
                                            errorMessage: 'Please enter your last name',
                                            value: form?.lastname,
                                        },
                                        email: {
                                            label: 'Email',
                                            type: 'email',
                                            required: true,
                                            containerClass: 'col-span-2',
                                            value: form?.email,
                                        },
                                        password: {
                                            label: 'Password',
                                            type: 'password',
                                            required: true,
                                            complexPassword: true,
                                            containerClass: 'col-span-2',
                                        },
                                        confirm_password: {
                                            type: 'hidden',
                                            value: null,
                                        },
                                    }}
                                    body={{...form}}
                                    inputsClassName='grid grid-cols-2 gap-4'
                                    url='/api/account'
                                    method='POST'
                                    buttonText='Create Account'
                                    callback={context.signin}
                                />

                                <Button
                                    text='Go Back'
                                    variant='text'
                                    className='mt-2'
                                    action={() => setStep(1)}
                                />
                            </>
                        )
                    }

                    <div className='mt-4'>
                        Already registered? <Link url='/signin' text='Sign In'/>
                    </div>
                </Card>
            </Row>
        </Animate>
    );
}
