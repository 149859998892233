import { Icon } from "components/lib";
import { useRef } from "react";

const AccordionItem = ({ children, title, logo, id, active, handleToggle }) => {
    const ref = useRef();

    return (
        <div className="rc-accordion-card">
            <div className="rc-accordion-header">
                <div className={`rc-accordion-toggle p-3 ${active ? 'active' : ''}`} onClick={() => handleToggle(id)}>
                    <div className="flex items-center gap-6">
                        {logo && logo()}
                        <h5 className="rc-accordion-title">{title}</h5>
                    </div>
                    <Icon image={`chevron-${active ? 'up' : 'down'}`} />
                </div>
            </div>
            <div className={`rc-collapse ${active ? 'show' : ''}`}>
                <div ref={ref} className="rc-accordion-body">
                    {children}
                </div>
            </div>
        </div>
    )
}


export default AccordionItem;