/***
*
*   HERO
*   Hero section on landing page
*
*   PROPS
*   title: h1 title
*   tagline: h2 tagline
*   image: source object
*   alt: img alt tag
*
**********/

import { Animate, Content, Button, Image, ClassHelper } from 'components/lib';
import Style from './hero.tailwind.js';

export function Hero(props) {

  const heroStyle = ClassHelper(Style, {

    hero: true,
    className: props.className

  });

  return (
    <section className={heroStyle}>
      <Content>
        {
          (props.tagline || props.title) && (

            <Animate type='pop'>
              <section className={Style.blurb}>

                {props.title && <h1 className={Style.title}>{props.title}</h1>}
                {props.tagline && <h2 className={Style.tagline}>{props.tagline}</h2>}

              </section>
            </Animate>
          )
        }
        {
          props.image && (
            <Animate>
              <Image className={Style.image} source={props.image} alt={props.alt} />
            </Animate>
          )
        }

      </Content>
    </section>
  )
}