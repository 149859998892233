/***
*
*   FOOTER (homepage)
*   Static homepage footer
*
**********/

import { Animate, Row, Content, Link } from 'components/lib'
import Style from './footer.tailwind.js';

export function Footer(props) {

  return (
    <Animate type='slideup'>
      <footer className={Style.footer}>
        <Row>
          <Content>

            <nav className={Style.nav}>
              <Link url='/' text='Home' className={Style.link} color='dark' />
              <Link url='/signup' text='Sign up' className={Style.link} color='dark' />
              <Link url='https://enzak.com/terms-of-service/' text='Terms' className={Style.link} color='dark' />
              <Link url='https://enzak.com/privacy-policy/' text='Privacy' className={Style.link} color='dark' />
            </nav>

            <div className={Style.copyright}>{`Copyright © Enzak ${new Date().getFullYear()}`}</div>
            <address className={Style.address}>Enzak Inc</address>

          </Content>
        </Row>
      </footer>
    </Animate>
  );
}