import React, { useContext, useState } from 'react'
import { AuthContext } from 'components/lib'
import AvatarEdit from 'react-avatar-edit'
import NameAvatar from 'react-avatar';

export function Avatar(props) {
    const [preview, setPreview] = useState(null)
    const authContext = useContext(AuthContext)
    const [open, setOpen] = useState(false)

    function dataURLtoBlob(dataURL) {
        var arr = dataURL.split(',');
        var mime = arr[0].match(/:(.*?);/)[1];
        var bstr = atob(arr[1]);
        var n = bstr.length;
        var u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    }

    return (
        <div className='flex flex-col gap-4 items-center'>
            <div
                role='button'
                className='relative'
                onClick={() => { setOpen(true); }}
            >
                {
                    (!props.value && !preview) ? (
                        <NameAvatar
                            name={authContext.user?.name}
                            color={NameAvatar.getRandomColor('sitebase')}
                            size={70}
                            round={true}
                            textSizeRatio={2.5}
                        />
                    ) : (
                        <img
                            src={preview || props.value}
                            alt={'avatar preview'}
                            width={70}
                            height={70}
                            className='border rounded-full'
                        />
                    )

                }
                <span className='text-center absolute bottom-1 left-[50%] translate-x-[-50%] bg-gray-200 text-xs w-[calc(100%-2px)] rounded-b-full'>Change</span>
            </div>

            {
                open && (
                    <AvatarEdit
                        width={400}
                        height={300}
                        onClose={() => setOpen(false)}
                        onCrop={(crop) => {
                            setPreview(crop)
                            props.onChange(props.name, dataURLtoBlob(crop), true)
                        }}
                    />
                )
            }
        </div>
    )
}