import { useAPI, Animate, Card, Select } from 'components/lib';
import React, { useEffect } from 'react'
import PaymentMethods from './PaymentMethods';
import History from './History';
import { Link, useNavigate, useParams } from 'react-router-dom';

function CampaignBilling() {
    const { id } = useParams()
    const { data, loading } = useAPI('/api/campaigns/userList/names');
    const navigate = useNavigate();

    useEffect(() => {
        if (!id && data?.campaigns?.length > 0) {
            navigate(`/billing/${data.campaigns[0].id}`)
        }

    }, [id, data])

    return (
        <Animate loading={loading}>
            {
                !loading && data?.campaigns?.length === 0 ? (
                    <p>You have no campaigns</p>
                ) : (
                    <div className="flex flex-col lg:flex-row gap-6">
                        <div className="w-full lg:min-w-[21rem] lg:max-w-[21rem] flex flex-col gap-y-3 overflow-y-auto max-h-[30vh] lg:max-h-[80vh] light-scrollbar">
                            {
                                data?.campaigns
                                    .map(camp => (
                                        <Link
                                            key={camp.id}
                                            to={`/billing/${camp.id}`}
                                            className={`${camp.id === id ? 'bg-white shadow-lg' : 'bg-[#eee]'} duration-200 border flex w-full items-center justify-between gap-5 px-4 py-3 rounded-lg border-solid`}
                                        >
                                            <div className="flex flex-col items-stretch">
                                                <span
                                                    className={`text-base font-bold leading-6 ${camp.id === id ? 'text-blue-500' : ''}`}
                                                    aria-label="Los Angeles Office"
                                                >
                                                    {camp.name}
                                                </span>
                                                {/* <div className="items-stretch flex gap-2 mt-2">
                                        <span className="text-neutral-800 text-sm font-semibold leading-5 grow whitespace-nowrap">{camp.vms} Voicemails</span>
                                        <span className="text-neutral-800 text-sm leading-5">/</span>
                                        <span className="text-neutral-800 text-sm font-semibold leading-5 grow whitespace-nowrap">{camp.cbs} CallBacks</span>
                                    </div> */}
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                <path d="M6 3.33331L10.6667 7.99998L6 12.6666" stroke="#212121" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </Link>
                                    ))
                            }
                        </div>

                        {
                            id && (
                                <div className='w-full'>
                                    <PaymentMethods />
                                    <div className='py-5'></div>
                                    <History />
                                </div>
                            )
                        }
                    </div>
                )
            }
        </Animate>
    )
}

export default CampaignBilling