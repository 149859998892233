import { Label, Error, Icon, ClassHelper, PopOver } from 'components/lib';
import Style from './input.tailwind.js';

export function TextInput(props) {

  const error = props.errorMessage || 'Please enter a value';

  function validate(e) {
    let value = e ? e.target.value : '';
    let valid = undefined;

    if (!props.required)
      valid = true


    // input is required and value is blank
    if (props.required && value === '')
      valid = false;

    // input isn't required and value is blank
    if (!props.required && value === '')
      valid = true;

    if (props.required && value !== '')
      valid = true;


    // update the parent form
    props.onChange?.(props.name, value, valid);

  }

  // style
  const textStyle = ClassHelper(Style, {

    textbox: true,
    className: props.className,
    error: props.valid === false,
    success: props.valid === true,
    warning: props.warning,
    disabled: props.disabled

  });

  return (
    <div className={Style.input}>

      <div className='relative'>
        {
          (props.label || props.popOver) && (
            <div className='flex items-center gap-2 mb-2'>
              {props.label &&
                <Label
                  text={props.label}
                  required={props.required}
                  for={props.name}
                  className='!mb-0'
                  labelBold={props.labelBold}
                />}
              {
                props.popOver && <PopOver text={props.popOver} />
              }
            </div>
          )
        }

        <div className='relative'>
          {props.type === 'textarea' ?

            <textarea
              id={props.id}
              name={props.name}
              value={props.value}
              className={textStyle}
              placeholder={props.placeholder}
              onChange={e => {
                props.onChange?.(props.name, e.target.value, undefined, 'change')
                validate(e)
              }}
              onBlur={e => {
                validate(e)
                props.onBlur?.(e)
              }}
              onFocus={e => {
                props.onChange?.(props.name, e.target.value, true, 'focus')
                if (e.target.value) {
                  validate(e)
                }
                props.onFocus?.(e)
              }}
              disabled={props.disabled}
              rows={props.rows}
              cols={props.cols}
            >
            </textarea>

            :

            <input
              type='text'
              ref={props.ref}
              id={props.id}
              name={props.name}
              value={props.value}
              className={textStyle}
              placeholder={props.placeholder}
              autocomplete={props.autocomplete}
              onChange={e => {
                props.onChange?.(props.name, e.target.value, undefined)
                validate(e)
              }}
              onBlur={e => {
                validate(e)
                props.onBlur?.(e)
              }}
              onFocus={e => {
                props.onChange?.(props.name, e.target.value, true)
                if (e.target.value) {
                  validate(e)
                }
                props.onFocus?.(e)
              }}
              disabled={props.disabled}
            />
          }

          {
            props.loading && (
              <div className='absolute top-0 left-0 h-full w-full bg-gray-100 flex items-center justify-center'>
                <span>{props.loadingText}</span>
              </div>
            )
          }
        </div>
      </div>

      {props.valid === false &&
        <Error message={error} />}

      {props.valid === true &&
        <Icon
          image='check'
          color='#8CC57D'
          className={Style.successIcon}
          size={16}
        />}

    </div>
  );
}
