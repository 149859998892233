import { ClassHelper, Label } from 'components/lib';
import Style from './checkbox.tailwind.js';
import { useId } from 'react';
import { Checkbox as MCheckbox } from '@material-tailwind/react'

export function Checkbox({ children, ...props }) {
  const id = useId()

  const checkStyle = ClassHelper(Style, {
    className: props.className,
    ...props
  });

  const labelStyle = ClassHelper({ base: Style.label }, {
    className: props.labelClassName
  })


  return (
    <div className={Style.flex}>
      <MCheckbox
        {...props}
        className={checkStyle}
        type='checkbox'
        name={props.name}
        id={id}
        required={props.required}
        checked={props.checked}
        defaultChecked={props.defaultChecked}
        value={true}
        onChange={e => {
          props?.callback && props.callback(props.index)
          props?.onChange && props.onChange(props.name, e.target.checked)
        }}
        disabled={props.disabled}
      />
      {
        children && children
      }
      {
        props.option && <Label
          text={props.option}
          required={props.required}
          for={id}
          className={labelStyle}
        />
      }

    </div>
  );
}