const Style = {

  wrapper: 'h-24 px-1',
  nav: 'absolute top-1/2 -translate-y-1/2 right-0',
  logo: 'absolute top-1/2 -translate-y-1/2 left-0 m0',
  transparent: 'bg-none bg-transparent',
  color: 'bg-brand2-500',
  link: 'mr-5 last:mr-0 no-underline !text-white hover:!text-gray-100 duration-200',
  button: 'inline-block px-3 py-2',

}

export default Style;