import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';

export function LineChart(props){

  let options = {...props.options };

  return <Chart type='line' data={ props.data } options={ options } />

}
