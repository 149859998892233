/***
*
*   USERS
*   Enables an admin to manage the users in their application
*
**********/

import React, { Fragment, useContext, useState, useEffect } from 'react';
import { ViewContext, Card, Table, Button, Animate, TitleRow, useAPI, Event, ModalContainer, EmailInput, HiddenInput, Select, MultiSelect, Message, useView } from 'components/lib';
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import Nav from './nav'
import { Link } from 'react-router-dom';
import moment from 'moment-timezone'

export function Users(props) {

  const context = useContext(ViewContext);
  const data = useAPI('/api/account/users');
  const timezone = useAPI('/api/account/timezone');
  const campaigns = useAPI('/api/campaigns/userList/names');
  const [users, setUsers] = useState([]);
  const view = useView()

  const [invite, setInvite] = useState(false)
  const [inviteData, setInviteData] = useState({
    email: '',
    emailErr: null,
    timezone: 'America/Chicago',
    permission: 'user',
    campaign_access: []
  })

  const getValid = (name) => {
    return valids.length && !valids.includes(name)
  }

  const [valids, setValids] = useState([])

  const inviteMutation = useMutation({
    mutationKey: ['invite'],
    mutationFn: (data) => axios.post('/api/invite', data).then(res => res.data?.data)
  })

  const emailExistMutation = useMutation({
    mutationKey: ['user', 'email-exist'],
    mutationFn: (email) => axios.post('/api/user/email-exist', { email }).then(res => res.data?.data)
  })

  const handleInviteDataChange = async (name, value, valid) => {
    setInviteData(prev => ({ ...prev, [name]: value }))

    if (!valid) {
      setValids(prev => [...prev, name])
    } else {
      setValids(prev => prev.filter(p => p !== name))
    }
  }

  const handleEmailExist = async (e) => {
    try {
      const existRes = await emailExistMutation.mutateAsync(e.target.value)
      if (existRes.exist) {
        setInviteData(prev => ({ ...prev, emailErr: existRes.message }))
        setValids(prev => [...prev, 'email'])
      }
      else {
        setValids(prev => prev.filter(p => p !== 'email'))
        setInviteData(prev => ({ ...prev, emailErr: null }))
      }
    } catch (err) {
      context.handleError(err)
    }
  }


  // useEffect(() => {
  //   if (timezone.data?.timezone) {
  //     setInviteData(prev => ({ ...prev, timezone: timezone.data?.timezone }))
  //   }

  // }, [timezone.data])

  useEffect(() => {
    if (inviteMutation.isSuccess) {
      let state = [...users];


      inviteMutation.data?.forEach(invite => {
        if (!state.find(x => x.id === invite.id)) {
          state = [...state, {
            id: invite.id,
            email: invite.email,
            firstname: '-',
            lastname: '-',
            phonenumber: '-',
            timezone: invite.timezone,
            date_created: invite.date_sent,
            permission: invite.permission || 'user',
            campaign_access: invite.campaign_access,
            status: 'Invited',
            actions: {
              invite: resendInvite,
              delete: deleteInvite
            },
          }]
        }
      });

      Event('invited_user');
      setUsers(state);
      setInvite(false)
      setInviteData({
        email: '',
        timezone: '',
        permission: 'user',
        campaign_access: []
      })
    }

  }, [inviteMutation.isSuccess])

  useEffect(() => {
    if (inviteMutation.isError) {
      context.handleError(inviteMutation.error?.response?.data?.data?.message || inviteMutation.error)
    }

  }, [inviteMutation.isError])


  function editUser(user, callback) {
    if (user.permission === 'master') {
      view.notification.show('Master can only be edited throught admin', 'error', true)
      return
    }
    context.modal.show({
      title: 'Update User',
      form: {
        firstname: {
          value: user.firstname,
          type: 'hidden'
        },
        lastname: {
          value: user.lastname,
          type: 'hidden'
        },
        name: {
          containerClass: 'col-span-2',
          label: 'Name',
          type: 'text',
          value: `${user.firstname} ${user.lastname}`,
          required: true,
          disabled: true,
        },
        email: {
          containerClass: 'col-span-2',
          label: 'Email',
          type: 'email',
          value: user.email,
          required: true,
          disabled: true
        },
        phonenumber: {
          containerClass: 'col-span-2',
          label: 'Phonenumber',
          type: 'phone',
          value: user.phonenumber,
          required: true,
        },
        timezone: {
          containerClass: 'col-span-2',
          label: 'Timezone',
          type: 'select',
          value: user.timezone,
          options: moment.tz.names()
            .filter(t => t.startsWith('America/') || t.startsWith('US/'))
            .map(t => ({ value: t, label: t })),
          required: true,
        },
        permission: {
          containerClass: 'col-span-2',
          label: 'Access Level',
          type: 'select',
          value: user.permission,
          required: true,
          options: [
            { value: 'user', label: 'User' },
            { value: 'manager', label: 'Manager' },
            { value: 'admin', label: 'Admin' }
          ]
        },
        campaign_access: {
          containerClass: 'col-span-2',
          label: 'Campaign Access',
          type: 'multi',
          required: true,
          value: campaigns.data?.campaigns?.filter(c => user.campaign_access?.split(',')?.includes(c.id))?.map(c => ({
            label: c.name, value: c.id
          })),
          options: campaigns.data?.campaigns?.map(c => ({
            label: c.name, value: c.id
          }))
        }
      },
      inputsClassName: 'grid grid-cols-2 gap-2',
      buttonText: 'Save',
      url: `/api/account/user/${user.id}`,
      method: 'PATCH'

    }, (res) => {
      context.notification.show(user.email + ' was updated', 'success', true);
      data.refetch()
    });
  }

  function deleteUser(user, callback) {
    if (user.permission === 'master') {
      view.notification.show('Master can only be deleted throught admin', 'error', true)
      return
    }
    context.modal.show({
      title: 'Delete User',
      form: {},
      buttonText: 'Delete User',
      text: `Are you sure you want to delete ${user.email}?`,
      url: `/api/user/${user.id}`,
      method: 'DELETE',
      destructive: true

    }, () => {

      context.notification.show(user.email + ' was deleted', 'success', true);
      data.refetch()
    });
  }

  function deleteInvite(data, callback) {

    context.modal.show({
      title: 'Delete Invite',
      form: {},
      buttonText: 'Delete Invite',
      text: `Are you sure you want to delete the invite for ${data.email}?`,
      url: `/api/invite/${data.id}`,
      method: 'DELETE',
      destructive: true

    }, () => {

      let state = [...users];
      state.splice(state.findIndex(x => x.id === data.id), 1);
      context.notification.show(`${data.email}'s invite was deleted`, 'success', true);
      setUsers(state);

    });
  }

  async function resendInvite(data) {
    try {

      await axios({

        url: '/api/invite',
        method: 'post',
        data: { email: data.email }

      });

      context.notification.show(`Invite re-sent to ${data.email}`, 'success', true);

    }
    catch (err) {

      context.handleError(err);

    }
  }

  useEffect(() => {
    // format the user list
    let list = [];

    if (data?.data?.users?.length) {
      list = data.data.users.map(x => {
        return {
          id: x.id,
          email: x.email,
          name: `${x.firstname} ${x.lastname}`,
          firstname: x.firstname,
          lastname: x.lastname,
          phonenumber: x.phonenumber,
          timezone: x.timezone,
          date_created: x.date_created,
          permission: x.permission,
          campaign_access: x.campaign_access,
          status: x.verified ? 'Verified' : 'Registered'
        }
      })
    }

    if (data?.data?.invites?.length) {
      data.data.invites.forEach(x => {
        list.push({
          id: x.id,
          email: x.email,
          name: '-',
          firstname: '-',
          lastname: '-',
          phonenumber: '-',
          timezone: x.timezone,
          date_created: x.date_sent,
          permission: x.permission || 'user',
          campaign_access: x.campaign_access,
          status: 'Invited',

        });
      });
    }

    setUsers(list);

  }, [data.data]);

  // attach the per row actions for invites
  if (users.length) {
    users.forEach(u => {
      if (u.status === 'Invited') {
        u.actions = {
          invite: resendInvite,
          delete: deleteInvite
        }
      }
    })
  }


  return (
    <Fragment>
      <Nav />

      <Animate>
        <Card loading={data.loading || timezone.loading || campaigns.loading}>
          {
            (!data.data?.has_sources) && (
              <Message
                type='warning'
                title='Link billing information to your account'
              >
                <p>
                  No billing info linked. <Link className='text-blue-400 underline' to='/account/billing'>Link now!</Link> Please provide a payment method to set up new users and assign them to campaigns.
                </p>
              </Message>
            )
          }
          <Message
            type='info'
            title={`Expand Your Team's Potential with Enzak`}
            text={`Effortlessly add new users to your system at only $${data.data?.price_per_user} per month each, and empower your business with enhanced capabilities and efficiency.`}
          />


          <TitleRow title={`Total Users: ${data.data?.paid_users}`}>
            <Button color='blue' size={'sm'} variant='outlined' text='Add User' action={() => setInvite(true)} />
          </TitleRow>

          {
            invite && (

              <ModalContainer close={() => setInvite(false)}>
                <Card title='Invite User'>
                  {
                    data.data?.has_sources ? (
                      <>
                        <EmailInput
                          label='Email'
                          name='email'
                          placeholder='Jhon@gmail.com'
                          required={true}
                          value={inviteData.email}
                          onChange={handleInviteDataChange}
                          valid={getValid('email')}
                          onBlur={handleEmailExist}
                          errorMessage={inviteData?.emailErr}
                        />

                        <Select
                          label='Timezone'
                          name='timezone'
                          value={inviteData.timezone}
                          options={moment.tz.names()
                            .filter(t => t.startsWith('America/') || t.startsWith('US/'))
                            .map(t => ({ value: t, label: t }))}
                          required={true}
                          onChange={handleInviteDataChange}
                          valid={getValid('timezone')}
                        />

                        <Select
                          label='Permission'
                          name='permission'
                          value={inviteData.permission}
                          options={[
                            { value: 'user', label: 'User' },
                            { value: 'manager', label: 'Manager' },
                            { value: 'admin', label: 'Admin' },
                          ]}
                          required={true}
                          onChange={handleInviteDataChange}
                          valid={getValid('permission')}
                        />

                        <MultiSelect
                          name='campaign_access'
                          label='Campaign Access'
                          required={true}
                          value={inviteData.campaign_access}
                          options={
                            campaigns.data?.campaigns
                              ?.filter(c => c.status === 'active')
                              ?.map(c => ({ label: c.name, value: c.id }))
                          }
                          onChange={handleInviteDataChange}
                          valid={getValid('campaign_access')}
                        />

                        <div className='flex gap-2 flex-wrap mt-4'>
                          <Button
                            small
                            text={'Invite'}
                            disabled={valids.length || Object.values(inviteData).filter(d => d != null).some(d => !d.length) || inviteMutation.isLoading}
                            action={() => inviteMutation.mutate(inviteData)}
                          />
                          <Button small text={'Cancel'} color='outline' action={() => setInvite(false)} />
                        </div>
                      </>
                    ) : (
                      <p>You must first link your billing informations</p>
                    )
                  }
                </Card>
              </ModalContainer>
            )
          }


          <Card>
            <Table
              search
              className='restrict-width'
              data={users}
              loading={data.loading}
              show={['email', 'firstname', 'lastname', 'phonenumber', 'timezone', 'date_created', 'last_login', 'permission', 'status']}
              badge={{
                col: 'status', color: 'blue', condition: [
                  { value: 'verified', color: 'green' },
                  { value: 'registered', color: 'blue' },
                  { value: 'invited', color: 'orange' }
                ]
              }}
              actions={{
                edit: editUser,
                delete: deleteUser,
              }}
            />
          </Card>
        </Card>



      </Animate>
    </Fragment>
  );
}
