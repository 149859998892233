import {useEffect, useCallback, useMemo} from "react";
import {createEditor, Editor, Transforms, Node, Range} from "slate";
import {withHistory} from "slate-history";
import {Slate, Editable, withReact} from 'slate-react';
import PropTypes from "prop-types";
import {Element} from "./Element.jsx";


export const replaceTags = [
    "working_hours_link",
    "after_hours_link"
];


const deserialize = (text, tags, shouldTransformTags, direction, existingMentions = new Set()) => {
    const transformTag = (tag) => {
        if (!shouldTransformTags) return tag;
        if (direction === 'leftToRight') {
            return tag.replace(replaceTags[0], replaceTags[1]);
        } else if (direction === 'rightToLeft') {
            return tag.replace(replaceTags[1], replaceTags[0]);
        }
        return tag;
    };

    // Split text by single or multiple new lines
    let lines = text.split(/\n/);

    // **Remove trailing empty lines**
    while (lines.length > 0 && lines[lines.length - 1].trim() === '') {
        lines.pop();
    }

    const paragraphs = [];

    for (let i = 0; i < lines.length; i++) {
        let line = lines[i];
        const tagRegex = new RegExp(tags.map(tag => `(${tag})`).join('|'), 'gi');
        const children = [];
        let lastIndex = 0;
        let match;

        while ((match = tagRegex.exec(line)) !== null) {
            if (match.index > lastIndex) {
                children.push({text: line.slice(lastIndex, match.index)});
            }

            const transformedTag = transformTag(match[0]);

            if (!existingMentions.has(transformedTag)) {
                children.push({
                    type: 'mention',
                    character: transformedTag,
                    children: [{text: ''}]
                });
            }

            // **If the character after the tag is 'x', skip it**
            if (line[match.index + match[0].length] === 'x') {
                lastIndex = match.index + match[0].length + 1;
            } else {
                lastIndex = match.index + match[0].length;
            }
        }

        if (lastIndex < line.length) {
            children.push({text: line.slice(lastIndex)});
        }

        paragraphs.push({
            type: 'paragraph',
            children: children.length > 0 ? children : [{text: ''}],
        });
    }

    return paragraphs;
};


const TagTextEditor = ({editorRef, value, setValue, tags, shouldTransformTags = true, direction = 'rightToLeft'}) => {
    const renderElement = useCallback(props => <Element {...props} editorRef={editorRef}/>, [editorRef]);
    const editor = useMemo(
        () => withMentions(withReact(withHistory(createEditor()))),
        []
    );

    useEffect(() => {
        if (value) {
            editorRef.current = editor;
        }
    }, [editor, editorRef, value]);

    /**
     * Custom paste handler that intercepts paste events, processes the pasted content,
     * and inserts it as Slate nodes into the editor.
     * @param {Event} event - The paste event.
     */
    const handlePaste = useCallback((event) => {
        event.preventDefault();
        const editor = editorRef.current;

        const {selection} = editor;

        const existingMentions = new Set();

        if (editor) {
            for (const [node, path] of Node.nodes(editor, {at: [], match: n => n.type === 'mention'})) {
                let isInSelection = false;

                if (selection) {
                    const nodeRange = Editor.range(editor, path);

                    if (Range.isCollapsed(selection)) {
                        isInSelection = false;
                    } else if (Range.intersection(selection, nodeRange)) {
                        isInSelection = true;
                    }
                }

                if (!isInSelection) {
                    existingMentions.add(node.character);
                }
            }
        }

        const slateFragment = event.clipboardData.getData('application/x-slate-fragment');

        let pastedNodes = [];

        const transformTag = (tag) => {
            if (!shouldTransformTags) return tag;
            if (direction === 'leftToRight') {
                return tag.replace(replaceTags[0], replaceTags[1]);
            } else if (direction === 'rightToLeft') {
                return tag.replace(replaceTags[1], replaceTags[0]);
            }
            return tag;
        };

        if (slateFragment) {
            // Decode the Slate fragment from the clipboard
            const decoded = decodeURIComponent(window.atob(slateFragment));
            const fragment = JSON.parse(decoded);

            // Transform tags and remove duplicate mentions from the fragment
            const transformAndRemoveDuplicateMentions = (nodes) => {
                return nodes.map(node => {
                    if (node.type === 'mention') {
                        // Transform the tag
                        const transformedTag = transformTag(node.character);

                        if (existingMentions.has(transformedTag)) {
                            // Replace duplicate mention with empty text node
                            return {text: ''};
                        } else {
                            return {
                                ...node,
                                character: transformedTag,
                                children: [{text: ''}]
                            };
                        }
                    } else if (node.children) {
                        return {...node, children: transformAndRemoveDuplicateMentions(node.children)};
                    } else {
                        return node;
                    }
                });
            };

            pastedNodes = transformAndRemoveDuplicateMentions(fragment);
        } else {
            // Handle plain text paste
            let pastedText = event.clipboardData.getData('text/plain');

            // Step 1: Replace multiple line breaks with a placeholder
            let tempText = pastedText.replace(/\n{2,}/g, '<<PARA_BREAK>>');

            // Step 2: Remove unwanted single newlines (not after sentence-ending punctuation)
            tempText = tempText.replace(/([^\.\!\?\n])\s*\n\s*/g, (match, p1, offset, string) => {
                let nextCharIndex = offset + match.length;
                let restOfString = string.slice(nextCharIndex);
                let nextCharMatch = restOfString.match(/\S/);
                let nextChar = nextCharMatch ? nextCharMatch[0] : '';

                if (/[,.;:!?]/.test(nextChar)) {
                    return p1;
                } else {
                    return p1 + ' ';
                }
            });

            // Step 3: Restore paragraph breaks
            let cleanedPastedText = tempText.replace(/<<PARA_BREAK>>/g, '\n\n');

            // Step 4: Remove any spaces before punctuation
            cleanedPastedText = cleanedPastedText.replace(/\s+([,.;:!?])/g, '$1');

            // Trim any extra newlines at the end
            cleanedPastedText = cleanedPastedText.trimEnd();

            // Deserialize the cleaned pasted text, excluding existing mentions outside the selection
            pastedNodes = deserialize(cleanedPastedText, tags, shouldTransformTags, direction, existingMentions);
        }

        if (editor) {
            // Insert the cleaned-up content into the editor
            Transforms.insertFragment(editor, pastedNodes);
        }
    }, [editorRef, shouldTransformTags, direction]);


    return (
        <Slate
            editor={editor}
            initialValue={value}
            value={value}
            onChange={newValue => setValue(newValue)}
        >
            <Editable
                style={{
                    minHeight: "160px",
                    maxHeight: "348px",
                    overflowY: "auto",
                    outline: 'none',
                }}
                onPaste={handlePaste}
                renderElement={renderElement}
            />
        </Slate>
    )
}

const withMentions = editor => {
    const {isInline, isVoid} = editor;

    editor.isInline = element => {
        return element.type === 'mention' ? true : isInline(element);
    };

    editor.isVoid = element => {
        return element.type === 'mention' ? true : isVoid(element);
    };

    return editor;
}

TagTextEditor.propTypes = {
    editorRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({current: PropTypes.any})
    ]),
    value: PropTypes.array,
    setValue: PropTypes.func,
    shouldTransformTags: PropTypes.bool,
    direction: PropTypes.string
};

export default TagTextEditor;
