import { AuthContext } from 'components/lib';
import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'

const permissions = require('../../app/permissions');

export const useCanPermission = (permission, scope) => {
    const context = useContext(AuthContext);

    const userScope = context.user?.scope || []
    const userPermission = context.user?.permission

    return permissions[userPermission][permission] || userScope.includes(scope)
}

export function Permission({ children, permission, scope, redirect = false }) {
    const can = useCanPermission(permission, scope)

    if (can) {
        return children
    }

    if (redirect) return <Navigate to={'/'} />

    return <></>
}

export default Permission