/***
*
*   LOGO
*   Replace the image in /images with your own logo
*
**********/

import { Link, ClassHelper, Image } from 'components/lib';
import WhiteLogo from './images/logo-white.png';
import Style from './logo.tailwind.js';

export function Logo(props) {


  const logoStyle = ClassHelper(Style, props);

  return (
    <Link url={props.url || '/'} className={logoStyle}>
      <Image
        src={WhiteLogo}
        webp={WhiteLogo}
        title='Enzak'
        alt='Enzak'
        className={props.className}
      />
    </Link>
  )
}
