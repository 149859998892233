const Style = {

  input: 'relative mb-4 last:mb-0',
  base: `relative cursor-pointer w-full border border-solid border-slate-300`,

  select: 'relative w-[120%] appearance-none outline-none bg-transparent border-transparent cursor-pointer p-3',
  success: 'border-green-500 mb-0',
  error: 'text-red-600',

  wrapper: 'overflow-auto',

  errorWrapper: `border-0 before:absolute before:left-0 before:right-0 before:top-0 before:bottom-4 
      before:rounded before:border before:border-solid before:border-red-500 after:-mt-3`,

  warning: 'text-orange-500',
  warningWrapper: `border-0 before:absolute before:left-0 before:right-0 before:top-0 before:bottom-0
      before:rounded before:border before:border-solid before:border-orange-500`,

  message: 'mb-0',
  disabled: 'opacity-50 cursor-not-allowed',
  small: '!p-2',

}

export default Style;