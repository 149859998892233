import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { TitleRow, PaymentForm, InlineCreditCard, Badge, Animate, Button, Icon, useView } from 'components/lib';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

function PaymentMethods(props) {
    const { id } = useParams()
    const view = useView()

    const { data, isLoading } = useQuery({
        queryKey: ['campaign', id, 'sources'],
        queryFn: () => axios.get(`/api/campaign/${id}/sources`).then(res => res.data?.data)
    })


    const [sources, setSources] = useState([]);
    const [defaultSource, setDefaultSource] = useState(null);
    const [loadingDefault, setLoadingDefault] = useState(null)


    useEffect(() => {
        if (data) {
            setSources(data.sources)
            setDefaultSource(data.defaultSource)
        }

    }, [data])

    const deleteM = useMutation({
        mutationKey: ['campaign', id, 'sources'],
        mutationFn: (data) => axios.delete(`/api/campaign/${id}/sources/${data.source}`).then(res => res.data)
    })

    useEffect(() => {
        if (deleteM.data && deleteM.isSuccess) {
            setSources(sources.filter(s => s.id !== deleteM.data.source))
            setDefaultSource(deleteM.data.defaultSource)
        }
    }, [deleteM.data, deleteM.isSuccess])


    const defaultM = useMutation({
        mutationKey: ['campaign', id, 'sources', 'setdefault'],
        mutationFn: (data) => axios.post(`/api/campaign/${id}/sources/setdefault/${data.source}`).then(res => res.data)
    })

    useEffect(() => {
        if (defaultM.data && defaultM.isSuccess && defaultM.data?.status === 'success') {
            setDefaultSource(defaultM.data.source)
            setLoadingDefault(null)
        }
    }, [defaultM.data, defaultM.isSuccess])

    useEffect(() => {
        if (defaultM.isError && defaultM.error) {
            view.handleError(defaultM.error)
        }
    }, [defaultM.isError, defaultM.error])

    return (
        <TitleRow title='Payment Methods' loading={isLoading}>

            <div className='grid lg:grid-cols-2 mt-5 gap-y-10 gap-6'>
                <div className='flex flex-col gap-4'>
                    {
                        sources?.length > 0 &&
                        sources
                            .sort((a, b) => {
                                if (a.id === defaultSource) return -1;
                                if (b.id === defaultSource) return 1;
                                return 0;
                            })
                            .map((source, i) => (
                                <Animate key={i}>
                                    <div className='flex items-center gap-2'>
                                        <InlineCreditCard key={i} source={source} />
                                        {
                                            source.id === defaultSource ? (
                                                <Badge color='green' text='Default' />
                                            ) : (
                                                <>
                                                    <Button
                                                        loading={defaultM.isLoading && loadingDefault === source.id}
                                                        text='Set Default'
                                                        variant='text'
                                                        small
                                                        action={() => {
                                                            defaultM.mutate({ source: source.id })
                                                            setLoadingDefault(source.id)
                                                        }}
                                                    />
                                                    {
                                                        (sources?.length > 1) && (
                                                            <Button
                                                                loading={deleteM.isLoading}
                                                                variant='text'
                                                                small
                                                                color='red'
                                                                action={() => deleteM.mutate({ source: source.id })}
                                                            >
                                                                <Icon image='x' />
                                                            </Button>
                                                        )
                                                    }
                                                </>
                                            )
                                        }
                                    </div>
                                </Animate>
                            ))
                    }
                </div>
                <div className='w-full lg:max-w-sm'>
                    <PaymentForm
                        className='w-full'
                        inputs={{
                            token: {
                                label: 'Add new card',
                                type: 'creditcard',
                                required: true
                            }
                        }}
                        url={`/api/campaign/${id}/sources`}
                        method='POST'
                        callback={res => {
                            setSources(res.data.data)
                        }}
                        buttonText='Save Card'
                    />
                </div>
            </div>
        </TitleRow >
    )
}

export default PaymentMethods