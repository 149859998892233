import { Icon } from 'components/lib';
import React, { useRef, useState, useEffect } from 'react';
import { useId } from 'react';

export function AudioPlayer({ src }) {
    const progressId = useId();
    const [isPlaying, setIsPlaying] = useState(false);
    const [duration, setDuration] = useState(0);
    const [timeProgress, setTimeProgress] = useState(0);
    const audioRef = useRef(null);

    const [loaded, setLoaded] = useState(false)


    let animationFrameId = null;
    let lastTime = null;


    const handleTimeUpdate = () => {
        const duration = audioRef.current.duration;
        const progress = document.getElementById(progressId);
        const currentTime = audioRef.current.currentTime;

        if (!lastTime) {
            lastTime = currentTime;
        }

        const deltaTime = currentTime - lastTime;
        const percent = (currentTime / duration) * 100;

        if (deltaTime >= 0.05) {
            requestAnimationFrame(() => {
                progress.style.width = percent + '%';
            });
            lastTime = currentTime;
            setTimeProgress(currentTime);
        }

        if (currentTime >= duration) {
            cancelAnimationFrame(animationFrameId);
        } else {
            animationFrameId = requestAnimationFrame(handleTimeUpdate);
        }
    };

    useEffect(() => {
        if (audioRef.current === null) {
            audioRef.current = new Audio();
            audioRef.current.src = src;
            audioRef.current.preload = "none";
        }

        const audioElement = audioRef.current;

        const handleDurationChange = () => {
            setDuration(audioElement.duration);
        };

        audioElement.addEventListener('durationchange', handleDurationChange);
        audioElement.addEventListener('timeupdate', handleTimeUpdate);

        audioElement.load()

        return () => {
            audioElement.removeEventListener('durationchange', handleDurationChange);
            audioElement.removeEventListener('timeupdate', handleTimeUpdate);
        };
    }, [audioRef.current, src]);

    const togglePlayPause = () => {
        const audioElement = audioRef.current;

        if (isPlaying) {
            audioElement.pause();
        } else {
            if (!loaded) {
                audioElement.load()
                setLoaded(true)
            }
            audioElement.play();
        }
        setIsPlaying(!isPlaying);
    };

    const formatTime = (time) => {
        if (time && !isNaN(time)) {
            const minutes = Math.floor(time / 60);
            const formatMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
            const seconds = Math.floor(time % 60);
            const formatSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
            return `${formatMinutes}:${formatSeconds}`;
        }
        return '00:00';
    };

    return (
        <div className='flex items-center gap-4'>
            <div className="flex items-center gap-3 justify-self-center">
                <button
                    className='bg-brand-500 w-[32px] h-[32px] rounded-full flex items-center justify-center'
                    onClick={togglePlayPause}
                    aria-label={isPlaying ? "Pause" : "Play"}
                    size="lg"
                >
                    {
                        isPlaying ? (
                            <Icon image='pause' color='white' />
                        ) : (
                            <Icon image='play' color='white' />
                        )
                    }
                </button>
            </div>

            <div
                className='w-full h-[12px] bg-gray-300 relative rounded-full'
            >
                <div
                    className="rounded-full absolute top-0 left-0 h-full bg-brand-500"
                    id={progressId}
                    style={{
                        width: `${(timeProgress / duration) * 100}%`,
                        height: '12px',
                        transition: 'width .1s linear',
                    }}
                ></div>
            </div>

            <div className="flex gap-1 font-semibold text-base">
                <span className="text-[color:var(--Enzak-Blue,#3A82F6)]">{formatTime(timeProgress)}</span>
                {'/'}
                <span className="time">{formatTime(duration)}</span>
            </div>
        </div>
    )
}
