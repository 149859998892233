import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Animate, Loader, Message, Row } from "components/lib";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";


export default function VMCVUnsubscribe() {
    const { type, token } = useParams()
    const navigate = useNavigate()

    const isVM = type === 'vm'


    useEffect(() => {
        if (['vm', 'cb'].indexOf(type) === -1) {
            return navigate('/')
        }

    }, [isVM, navigate, type])

    const { status, isLoading } = useQuery({
        queryKey: ['campaign', 'vm_vb_unsubscribe', token],
        retry: false,
        queryFn: (data) => axios.post(`/api/campaign/vm_vb_unsubscribe/${encodeURIComponent(token)}`).then(res => res.data)
    })

    if (isLoading) {
        return <Loader />
    }

    return (
        <Animate type='pop'>

            <Row>
                <div className="w-full mx-auto max-w-[50rem] px-3" >
                    {
                        status === 'success'
                            ? <Message
                                type='success'
                                title='Success'
                                text={`You have been successfully unsubscribed from ${isVM ? 'Voicemail' : 'Callback'} notifications.`}
                            />
                            : <Message
                                type='error'
                                title='Error'
                                text={`Invalid token, please check the link or contact support.`}
                            />
                    }

                </div>
            </Row>

        </Animate>
    )
}