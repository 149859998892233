import { lazy } from "react"
import MissedCallLog from "views/campaign/MissedCallLog"
import VMCBContext from "views/campaign/VM_CB/Context"

const CallBack = lazy(() => import("views/campaign/CallBack"))
const CampaignStatistics = lazy(() => import("views/campaign/CampaignStatistics"))
const EditCampaign = lazy(() => import("views/campaign/_EditCampaign"))
const NewCampaign = lazy(() => import("views/campaign/NewCampaign"))
const VoiceMail = lazy(() => import("views/campaign/VoiceMail"))
const Campaigns = lazy(() => import("views/campaign/campaigns"))
const Archives = lazy(() => import("views/campaign/VM_CB/Archives"))


const Routes = [
    {
        path: '/campaigns',
        view: Campaigns,
        layout: 'app',
        permission: 'user',
        scope: 'campaign.read',
        title: 'Campaigns'
    },
    {
        path: '/new_campaign',
        view: NewCampaign,
        layout: 'app',
        permission: 'admin',
        scope: 'campaign.create',
        title: 'New Campaign'
    },
    {
        path: '/new_campaign/duplicate/:id',
        view: NewCampaign,
        layout: 'app',
        permission: 'admin',
        scope: 'campaign.create',
        title: 'New Campaign'
    },
    {
        path: '/new_campaign/draft/:draft',
        view: NewCampaign,
        layout: 'app',
        permission: 'admin',
        scope: 'campaign.create',
        title: 'New Campaign'
    },
    {
        path: '/edit_campaign/:id',
        view: EditCampaign,
        layout: 'app',
        permission: 'manager',
        scope: 'campaign.update',
        title: 'Edit Campaign'
    },
    {
        path: '/campaign_analytics/:id',
        view: CampaignStatistics,
        layout: 'app',
        permission: 'user',
        scope: 'campaign.analytics',
        title: 'Campaign Analytics'
    },
    // {
    //     path: '/campaign_performance/:id',
    //     view: CampaignPerformance,
    //     layout: 'app',
    //     permission: 'user',
    //     scope: 'campaign.analytics',
    //     title: 'Campaign Performance'
    // },
    {
        path: '/campaign/:id/vm',
        view: VoiceMail,
        layout: 'app',
        permission: 'user',
        title: 'Campaign VoiceMail',
        context: VMCBContext
    },
    {
        path: '/campaign/:id/cb',
        view: CallBack,
        layout: 'app',
        permission: 'user',
        title: 'Campaign CallBack',
        context: VMCBContext
    },
    {
        path: '/campaign/:id/ar',
        view: Archives,
        layout: 'app',
        permission: 'user',
        title: 'Campaign Archives',
        context: VMCBContext
    },
    {
        path: '/campaign/:id/missed_call_log',
        view: MissedCallLog,
        layout: 'app',
        permission: 'user',
        title: 'Missed Call Log',
    },
]



export default Routes;