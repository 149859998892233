import { useEffect, useRef } from 'react';
import io from 'socket.io-client';

export const useSocket = (URL) => {
    const socketRef = useRef(null);

    useEffect(() => {
        socketRef.current = io(URL);

        return () => {
            socketRef.current.disconnect();
        };
    }, [URL]);

    const emit = (eventName, data) => {
        if (socketRef.current) {
            socketRef.current.emit(eventName, data);
        }
    };

    const on = (eventName, callback) => {
        if (socketRef.current) {
            socketRef.current.on(eventName, callback);
        }
    };

    const off = (eventName, callback) => {
        if (socketRef.current) {
            socketRef.current.off(eventName, callback);
        }
    };

    return { emit, on, off };
};