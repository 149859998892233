/***
*
*   SIGN UP STEP 2
*   Verify email address
*   Step 1: create account
*   Step 2: verify email address
*   Step 3: select plan
*
**********/

import React, { useContext, useState, useEffect, useRef } from 'react';
import Axios from 'axios';
import { AuthContext, Animate, Row, Card, Message, Link, useNavigate, Button, useView, CircleSpinner } from 'components/lib';
import VerificationInput from 'react-verification-input';
import { useMutation } from '@tanstack/react-query';

export function SignupVerification() {
  const view = useView()
  const authContext = useRef(useContext(AuthContext));
  const user = authContext.current?.user;

  const navigate = useNavigate()
  const verInputRef = useRef()

  const [message, setMessage] = useState({
    type: 'info',
    title: 'Please Check Your Email',
    text: "We've sent a code to verify your email address. Please get the code and enter it below to confirm.",
  });

  const resendVerificationCode = useMutation({
    mutationFn: () => Axios.post('/api/user/verify/request'),
    onError: (e) => view.handleError(e),
    onSuccess: (res) => {
      setMessage(prev => ({ ...prev, type: 'success', title: 'Verification Code Sent' }))
      verInputRef.current?.focus()
    }
  })

  const verificationMutation = useMutation({
    mutationFn: (code) => Axios.post('/api/user/verify', { verification_code: code }),
    onError: (e) => view.handleError(e),
    onSuccess: (res) => {
      navigate(authContext.current.signin(res))
    }
  })

  useEffect(() => {
    verInputRef.current?.focus()
  }, [])

  useEffect(() => {
    if (user?.status === 'inactive') {
      resendVerificationCode.mutate()
    }
  }, [user])

  return (
    <Animate type='pop'>
      <Row title='Verify Your Email Address'>
        <Card loading={false} center>

          {
            user?.status === 'inactive' && (
              <Message
                type='warning'
                text={`We prioritize your security. Due to prolonged account inactivity, we need you to re-verify your account. Please follow the provided steps to regain access. Thank you for understanding.`}
                closable
              />

            )
          }
          <Message
            type={message.type}
            title={message.title}
            text={message.text}
          />

          <div className='flex flex-col gap-y-4 items-center justify-center'>
            {
              verificationMutation.isLoading && (
                <CircleSpinner
                  width={20}
                  height={20}
                  className='text-green-500 mx-auto'
                />
              )
            }

            <VerificationInput
              ref={verInputRef}
              length={6}
              classNames={{
                container: 'mt-4',
                character: 'border border-blue-300 rounded-md',
                characterSelected: 'border border-blue-300',
                characterInactive: 'bg-blue-50',
              }}
              onComplete={verificationMutation.mutate}
            />

            <div className="flex w-full">
              <Button
                text='Resend Verification Code'
                variant='text'
                className='text-sm !p-0 hover:bg-transparent active:bg-transparent'
                action={resendVerificationCode.mutate}
                loading={resendVerificationCode.isLoading}
                disabled={resendVerificationCode.isLoading}
              />
            </div>


          </div>

          <div className='mt-3'>
            <Link url='/account/profile' className='text-sm' text='Manage Your Account' />
          </div>

        </Card>
      </Row>
    </Animate>
  );
}
