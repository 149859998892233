import { Animate, Card, Select, useAPI, Message } from 'components/lib'
import AccordionItem from 'components/Accordion/AccordionItem'
import { Accordion } from 'components/lib'
import React, { useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'
import CoxLogo from 'assets/images/cox-logo.png'
import ComCastLogo from 'assets/images/comcast-logo.png'
import ATTLogo from 'assets/images/att-logo.png'
import SpectrumLogo from 'assets/images/spectrum-logo.png'
import VoipLogo from 'assets/images/voip-logo.png'
import { useParams } from 'react-router-dom'


function FAQ() {
  const { data, loading } = useAPI('/api/campaigns/userList/names');
  const [items, setItems] = useState([])
  const [campaign, setCampaign] = useState({})
  
  const { id } = useParams()

  useEffect(() => {
    if (data?.campaigns?.length > 0) {
      setCampaign(data?.campaigns.find(c => c.id === id) || data?.campaigns?.[0])
    }
  }, [data])

  useEffect(() => {
    if (campaign) {
      setItems([{
        id: 0,
        element: <CallForwarding callForwardingNumber={campaign.callforwarding_number} />,
        title: 'Activate, deactivate, call forwarding for your campaign',
        active: true
      }])
    }

  }, [campaign])


  const handleToggle = (id) => {
    setItems(prev => prev.map(item => {
      if (item.id === id) {
        return { ...item, active: !item.active }
      }
      return { ...item, active: false }
    }))
  }


  return (
    <Animate>
      {
        data?.campaigns?.length > 0 && (
          <Card loading={loading}>
            <Select
              label='Select a campaign'
              name='campaign'
              options={
                data?.campaigns
                  ?.filter(c => c.bw_order_status === 'Fully Provisioned')
                  ?.map(c => ({ label: c.name, value: c.id }))
              }
              default={id || data?.campaigns?.[0]?.id}
              onChange={(e, v) => setCampaign(data?.campaigns?.find(c => c.id === v))}
            />
            <p>If you can't see your campaign it's probably the call forwarding number is still in the provisioning status please allow up to 48h to fully activate your new number.</p>
          </Card>
        )
      }
      <Card loading={loading}>
        <Accordion>
          {
            items.map((item, index) => (
              <AccordionItem
                key={index}
                title={item.title}
                id={item.id}
                active={item.active}
                handleToggle={handleToggle}
              >
                {item.element}
              </AccordionItem>
            ))
          }
        </Accordion>
      </Card>
    </Animate>
  )
}


const CallForwarding = ({ callForwardingNumber = '(000) 000-0000' }) => {
  const [data, setData] = useState(items.map(i => ({ ...i, id: uuid(), active: false })))

  const handleToggle = (id) => {
    setData(data.map(item => {
      if (item.id === id) {
        return { ...item, active: !item.active }
      }
      return { ...item, active: false }
    }))
  }

  return (
    <Accordion>
      {data.map((item, index) => {
        let regex = /(COX MyAccount)/g
        return (
          <AccordionItem
            key={index}
            title={item.title}
            logo={() => (
              <img src={item.logo} className='w-[6rem] h-[4rem] object-contain' />
            )}
            id={item.id}
            active={item.active}
            handleToggle={handleToggle}
          >

            <Message
              type='warning'
              text='Warning! If you have any Answering Machine please make sure to disable it permanently otherwise the missed calls not transferring to our system.'
              closable
              small
            />

            <p className='whitespace-pre-line'>

              {item.text.split(regex).map((part, i) => regex.test(part) ? (
                <a key={i} href={item['myaccount']} target='_blank' rel="noreferrer" className='underline'>
                  {part}
                </a>
              ) : part
              )}
            </p>

            {
              item?.instructions?.length && (
                <>
                  <div className="mb-4">
                    <b>How to Activate Call Forwarding:</b>
                  </div>
                  <div className="flex flex-col gap-6 mb-4">
                    {
                      item?.instructions?.map((instruction, index) => (
                        <div className='flex flex-col gap-2' key={index}>
                          {instruction.text && (
                            <p className='whitespace-pre-line'>{instruction.text}</p>
                          )}

                          <h5 className='font-bold'>{instruction.type.split(/(callforwardingnumber)/g)[0]} {instruction.type.split(/(callforwardingnumber)/g)[1] === 'callforwardingnumber' && callForwardingNumber}</h5>

                          {instruction.activate && (
                            <span>
                              <b>To Activate: </b>Pick up your business phone and dial {instruction.activate}, then dial {callForwardingNumber}. This setting forwards calls to us when not answered after a set number of rings.
                            </span>
                          )}
                          {instruction.desactivate && (
                            <span>
                              <b>To Deactivate: </b>Dial {instruction.desactivate} {callForwardingNumber}.
                            </span>
                          )}
                        </div>
                      ))
                    }
                  </div>
                </>
              )
            }


            {
              item?.linkText?.split(':')[1]
                ? <p>{item?.linkText?.split(':')[0]}: <a className='underline pt-3' href={item?.link} target='_blank' rel="noreferrer">{item?.linkText?.split(':')[1]}</a></p>
                : <a href={item.link} target='_blank' rel="noreferrer" className='underline pt-3'>{item.linkText}</a>
            }

            <p className='mt-6 text-sm'>
              By following these simple steps, you can ensure that every call is a potential business opportunity.
            </p>

          </AccordionItem>
        )
      })
      }
    </Accordion>
  )
}

const items = [
  {
    logo: SpectrumLogo,
    title: "Spectrum Business",
    text: 'Please set both the call forwarding no answer and call forwarding busy\n\n',
    instructions: [
      {
        text: "",
        type: 'Call Forwarding When No Answer',
        activate: '*92',
        desactivate: '*93',
      },
      {
        text: "",
        type: 'Call Forwarding When Line is Busy',
        activate: '*90',
        desactivate: '*91',
      },
    ],
    linkText: 'Other call forwarding options available: spectrumbusiness.net',
    link: 'https://www.spectrumbusiness.net/support/voice/call-forwarding-features-and-access-codes?redirected=true '
  },
  {
    logo: ATTLogo,
    title: 'AT&T',
    text: 'Please set both the call forwarding no answer and call forwarding busy\n\n',
    instructions: [
      {
        text: "",
        type: 'Call Forwarding No Answer',
        activate: '*92',
        desactivate: '*93'
      },
      {
        text: "",
        type: 'Call Forwarding Busy',
        activate: '*90',
        desactivate: '*91'
      }
    ],
    linkText: 'Other call forwarding options available: att.com',
    link: 'https://www.att.com/support/smallbusiness/article/smb-local-long-distance/KM1181691'
  },
  {
    logo: ComCastLogo,
    title: "Comcast Business",
    text: 'Please set both the call forwarding no answer and call forwarding busy\n\n',
    instructions: [
      {
        text: "",
        type: 'Call Forwarding No Answer',
        activate: '*92',
        desactivate: '*93',
      },
      {
        text: "",
        type: 'Call Forwarding Busy',
        activate: '*90',
        desactivate: '*91',
      },
    ],
    linkText: 'Other call forwarding options available: business.comcast.com',
    link: 'https://business.comcast.com/support/article/voice/phone-managing-call-forwarding-routing'
  },
  {
    logo: CoxLogo,
    title: 'Cox Business',
    text: "Please set both the call forwarding no answer and call forwarding busy\n\n Cox Business provides you with several options to set up call forwarding in My Account online and mobile app. Log in to your COX MyAccount or mobil app and set up:\n\nBusy - when your phone is busy ensuring your callers avoid busy signals\nNo Answer - after a specific number of rings to prevent your calls from being unanswered",
    myaccount: "https://myaccount-business.cox.com/cbma/unauth/login",
    linkText: "COX Step by step Changing Call Forwarding Rules Online",
    link: "https://www.cox.com/business/support/changing-call-forwarding-rules-online.html"
  },
  {
    logo: VoipLogo,
    title: "VOIP Provider",
    text: "Please set both the call forwarding no answer and call forwarding busy\n\nIf you are utilizing the services of a Voice Over Internet Protocol (VOIP) provider, you can conveniently configure call forwarding options to redirect your calls to another number when you are unable to answer them. To access these settings, simply log in to your user portal, which is typically provided by your VOIP service provider. Once you have accessed your user portal, look for the call forwarding options and configure them according to your preferences.\nIn the event that you do not have access to a user portal, we recommend that you contact your VOIP service provider for assistance in setting up call forwarding. They will be able to guide you through the process and provide you with the necessary information to configure call forwarding for both no answer and call forwarding busy scenarios. By enabling call forwarding, you can ensure that you never miss that opportunity or sale, even when you are unable to answer it personally.",
  }
]

export default FAQ