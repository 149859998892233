import React, { Fragment, useEffect, useState } from 'react'
import Nav from './nav'
import { Animate, Card, Message, useAPI, Table, PaymentForm, Badge, InlineCreditCard, Button, Icon, useView } from 'components/lib'
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

const Messages = require('./messages.json');

export function Billing() {
    const { data, loading, refetch } = useAPI('/api/account/billing')
    const invoices = useAPI('/api/account/invoice')
    const view = useView()

    const [sources, setSources] = useState([]);
    const [defaultSource, setDefaultSource] = useState(null);
    const [loadingDefault, setLoadingDefault] = useState(null)

    useEffect(() => {
        if (data && data.sources) {
            setSources(data.sources)
            setDefaultSource(data.defaultSource)
        }

    }, [data])

    const deleteM = useMutation({
        mutationKey: ['account', 'sources'],
        mutationFn: (data) => axios.delete(`/api/account/sources/${data.source}`).then(res => res.data)
    })

    useEffect(() => {
        if (deleteM.data && deleteM.isSuccess) {
            setSources(sources.filter(s => s.id !== deleteM.data.source))
            setDefaultSource(deleteM.data.defaultSource)
        }
    }, [deleteM.data, deleteM.isSuccess])


    const defaultM = useMutation({
        mutationKey: ['account', 'sources', 'setdefault'],
        mutationFn: (data) => axios.post(`/api/account/sources/setdefault/${data.source}`).then(res => res.data)
    })

    useEffect(() => {
        if (defaultM.data && defaultM.isSuccess && defaultM.data?.status === 'success') {
            setDefaultSource(defaultM.data.source)
            setLoadingDefault(null)
        }
    }, [defaultM.data, defaultM.isSuccess])

    useEffect(() => {
        if (defaultM.isError && defaultM.error) {
            view.handleError(defaultM.error)
        }
    }, [defaultM.isError, defaultM.error])

    return (
        <Fragment>
            <Nav />

            <Animate>
                <Card loading={loading}>
                    {
                        (data?.status && data?.status !== 'active' && data?.status !== 'trialing') &&
                        <Message {...Messages[data?.status]} />
                    }

                    {
                        (data?.status && data?.status === 'active' && data?.status === 'trialing') && (
                            <Message
                                type='info'
                                title='Your Billing Cycle'
                            >
                                <div>{data?.sub_start} to {data?.sub_end}</div>

                            </Message>
                        )
                    }
                    <div className='grid lg:grid-cols-2 mt-5 gap-y-10 gap-6'>
                        <div className='flex flex-col gap-4'>
                            {
                                sources?.length <= 0 ?
                                    <p>No cards has been added</p>
                                    : sources
                                        .sort((a, b) => {
                                            if (a.id === defaultSource) return -1;
                                            if (b.id === defaultSource) return 1;
                                            return 0;
                                        })
                                        .map((source, i) => (
                                            <Animate key={i}>
                                                <div className='flex items-center gap-2'>
                                                    <InlineCreditCard key={i} source={source} />
                                                    {
                                                        source.id === defaultSource ? (
                                                            <Badge color='green' text='Default' />
                                                        ) : (
                                                            <>
                                                                <Button
                                                                    loading={defaultM.isLoading && loadingDefault === source.id}
                                                                    text='Set Default'
                                                                    variant='text'
                                                                    small
                                                                    action={() => {
                                                                        defaultM.mutate({ source: source.id })
                                                                        setLoadingDefault(source.id)
                                                                    }}
                                                                />
                                                                {
                                                                    (sources?.length > 1) && (
                                                                        <Button
                                                                            loading={deleteM.isLoading}
                                                                            variant='text'
                                                                            small
                                                                            color='red'
                                                                            action={() => deleteM.mutate({ source: source.id })}
                                                                        >
                                                                            <Icon image='x' />
                                                                        </Button>
                                                                    )
                                                                }
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            </Animate>
                                        ))
                            }
                        </div>
                        <div className='w-full md:max-w-sm'>
                            <PaymentForm
                                className='w-full'
                                inputs={{
                                    token: {
                                        label: 'Add new card',
                                        type: 'creditcard',
                                        required: true
                                    }
                                }}
                                url={`/api/account/sources`}
                                method='POST'
                                callback={(res) => {
                                    refetch()
                                    if (res.data?.refetchInvoice) {
                                        invoices.refetch()
                                    }
                                }}
                                buttonText='Save Card'
                            />
                        </div>
                    </div>
                </Card>

                <Card>
                    <Table
                        loading={invoices.loading}
                        badge={{
                            col: 'status', color: 'red', condition: [
                                { value: 'paid', color: 'green' },
                            ]
                        }}
                        data={invoices.data?.map(inv => ({
                            invoice_number: inv.number,
                            date: inv.date,
                            status: inv.status,
                            total: inv.total,
                            actions: { download: inv.invoice_pdf }
                        }))}
                    />
                </Card>
            </Animate>
        </Fragment>
    )
}