import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { Animate, AuthContext, Card, Paginate, Table, useSocket } from 'components/lib'
import React, { useEffect, useRef } from 'react'
import { useContext } from 'react';
import { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import NotificationSound from '../../../assets/notification_sound.wav'
import { Slide, toast } from 'react-toastify';

function MissedCallLog() {
    const { id } = useParams()
    const auth = useContext(AuthContext)

    const { data } = useQuery({
        queryKey: ['userList', 'names'],
        queryFn: () => axios.get('/api/campaigns/userList/names').then(res => res.data?.data)
    })

    const [offset, setOffset] = useState(0)
    const limit = 25

    const logsQuery = useQuery({
        queryKey: ['campaign', 'missed_call_log', id, offset, limit],
        queryFn: () => axios.get(`/api/campaign/${id}/missed_call_log?offset=${offset}&limit=${limit}`).then(res => res.data)
    })

    const total = logsQuery.data?.total
    const logs = logsQuery.data?.results

    const io = useSocket(process.env.REACT_APP_SMS_HANDLER_URL)
    const accountId = auth.user?.account_id || null
    const audio = new Audio(NotificationSound)
    const trigger = useRef()

    const notification = (text) => {

        trigger.current.click()
        toast(text, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Slide,
        });
    }

    useEffect(() => {
        const EVENT = `campaign_${accountId}_missed_call_log`

        const handleTestEvent = () => {
            logsQuery.refetch()
            notification('New mised call log !')
        };

        io.on(EVENT, handleTestEvent)

        return () => {
            io.off(EVENT, handleTestEvent);
        };
    }, [io, accountId])

    let cols = ['caller_id_name', 'caller_id_number', 'action']

    if (logs?.some(l => l.alternate_id_name)) cols.push('alternate_id_name')
    if (logs?.some(l => l.alternate_number)) cols.push('alternate_number')
    cols = [...cols, 'called_at']


    return (
        <Animate>
            <button
                ref={trigger}
                onClick={() => audio.play()}
                className='fixed z-[-999] top-0 right-0'
            />
            <div className="flex flex-col lg:flex-row gap-6">

                <div className="w-full lg:min-w-[21rem] lg:max-w-[21rem] flex flex-col gap-y-3 overflow-y-auto max-h-[30vh] lg:max-h-[80vh] light-scrollbar">
                    {
                        data?.campaigns && [{ id: 'all', name: 'All Campaigns', }, ...data?.campaigns]
                            .map(camp => (
                                <Link
                                    key={camp.id}
                                    to={`/campaign/${camp.id}/missed_call_log`}
                                    className={`${camp.id === id ? 'bg-white shadow-lg' : 'bg-[#eee]'} duration-200 border flex w-full items-center justify-between gap-5 px-4 py-3 rounded-lg border-solid`}
                                >
                                    <div className="flex flex-col items-stretch">
                                        <span
                                            className={`text-base font-bold leading-6 ${camp.id === id ? 'text-blue-500' : ''}`}
                                            aria-label="Los Angeles Office"
                                        >
                                            {camp.name}
                                        </span>
                                        {/* <div className="items-stretch flex gap-2 mt-2">
                                        <span className="text-neutral-800 text-sm font-semibold leading-5 grow whitespace-nowrap">{camp.vms} Voicemails</span>
                                        <span className="text-neutral-800 text-sm leading-5">/</span>
                                        <span className="text-neutral-800 text-sm font-semibold leading-5 grow whitespace-nowrap">{camp.cbs} CallBacks</span>
                                    </div> */}
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M6 3.33331L10.6667 7.99998L6 12.6666" stroke="#212121" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </Link>
                            ))
                    }
                </div>

                <div className='w-full'>
                    <div className="mb-4">
                        <Paginate
                            offset={offset}
                            limit={limit}
                            total={total}
                            loading={logsQuery.isLoading}
                            onChange={x => setOffset(x)}
                        />
                    </div>

                    <div className="shadow-sm">
                        <Table
                            loading={logsQuery.isLoading}
                            data={logs}
                            show={cols}
                        />
                    </div>
                </div>

            </div>
        </Animate>
    )
}

export default MissedCallLog