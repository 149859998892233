import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { TitleRow, Table } from 'components/lib';
import React from 'react'
import { useParams } from 'react-router-dom';


function History() {
    const { id } = useParams()

    const { data, isLoading } = useQuery({
        queryKey: ['campaign', id, 'invoice'],
        queryFn: () => axios.get(`/api/campaign/${id}/invoice`).then(res => res.data?.data)
    })


    return (
        <TitleRow title='History' loading={isLoading}>
            <Table
                search
                loading={isLoading}
                badge={{
                    col: 'status', color: 'red', condition: [
                        { value: 'paid', color: 'green' },
                    ]
                }}
                data={data?.map(x => {
                    return {
                        invoice_number: x.number,
                        date: x.date,
                        status: x.status,
                        total: x.total,
                        actions: { download: x.invoice_pdf }
                    }
                })}
            />
        </TitleRow>
    )
}

export default History