/***
*
*   BUTTON
*   Can be a standard button, icon button or with loading animation
*
*   PROPS
*   text: button label
*   action: callback function executed on click
*   params: object passed to the callback function (optional)
*   color: red/blue (default: green)
*   icon: icon image (optional)
*   iconPack: icon pack to use
*   iconSize: icon size
*   iconButton: true or false
*   alignIcon: left or right
*   small: render a smaller button
*   textOnly: text only
*   outline: outline button
*   rounded: round the corners
*   className: pass a custom class object
*   fullWidth: extend to full width of parent container
*   loading: boolean to toggle loading animation (optional)
*   disabled : boolean to disable the button (optional)
*
**********/

import { Fragment } from 'react';
import { Icon, ClassHelper, useNavigate } from 'components/lib';
import { Button as MButton } from "@material-tailwind/react";

import ButtonStyle from './button.tailwind.js';
import IconStyle from './icon.tailwind.js';

export function Button(props) {

  const navigate = useNavigate();

  const buttonStyle = ClassHelper(ButtonStyle, {
    ...props,
    ...{
      // [props.color]: !props.textOnly && props.color,
      [props.position || 'relative']: true,
      text: props.textOnly,
      iconButton: props.icon && !props.text,
      iconTextLeft: props.icon && props.text && !props.alignRight,
      iconTextRight: props.icon && props.text && props.alignRight,
      iconTextOnly: props.textOnly && props.icon && props.text,
      btn: props.iconButton || (!props.textOnly && !props.icon),
    }
  });

  const iconStyle = ClassHelper(IconStyle, {
    fill: props.fillIcon,
    alignLeft: props.alignLeft,
    alignRight: props.alignRight,
    insideButtonLeft: (props.iconButton || (!props.textOnly && !props.icon)) && !props.alignRight,
    insideButtonRight: (props.iconButton || (!props.textOnly && !props.icon)) && props.alignRight,
  })


  return (
    <MButton
      {...props}
      title={props.title}
      color={props.color || 'blue'}
      variant={props.variant || (props.icon && 'text')}
      type={props.type}
      className={buttonStyle}
      disabled={props.disabled || false}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();

        props.action && props.action(props.params);
        props.goto && navigate(props.goto);
        if (props.url) window.location = props.url;
      }}
    >

      {(props.icon && !props.loading) ?

        <Fragment>
          <Icon
            image={props.icon}
            pack={props.iconPack}
            color={props.iconColor || 'blue'}
            size={props.iconSize || props.size || 18}
            className={iconStyle}
          />
          {props.text && props.text}
          {props.children && props.children}
        </Fragment>

        : <>
          {props.text && props.text}
          {props.children && props.children}
        </>

      }
    </MButton>
  );
}

