/***
*
*   AUTH LAYOUT
*   Layout for the signup/signin pages
*
**********/

import { HomeNav, Footer } from 'components/lib';
import Style from './auth.tailwind.js';

export function AuthLayout(props) {

  return (
    <main className={Style.auth} style={{
      ...process.env.REACT_APP_SERVER === 'DEV' && { marginTop: '2.5rem' }
    }}>
      <HomeNav />
      {<props.children {...props.data} />}
      <Footer />
    </main>
  );
}