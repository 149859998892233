/***
*
*   HOME NAV
*   Navigation used on the main external website. Renders a dashboard link
*   if the user is signed in, or a sign up link if they are not
*
**********/

import { useContext } from 'react';
import { AuthContext, Logo, Content, ClassHelper } from 'components/lib';
import Style from './home.tailwind.js';
import { Button as MButton } from '@material-tailwind/react';
import { Link } from 'react-router-dom';

export function HomeNav(props) {

  // context
  const context = useContext(AuthContext);

  const css = ClassHelper(Style, {

    wrapper: true,
    color: !props.transparent,
    transparent: props.transparent

  });

  return (
    <section className={css}>
      <Content>

        <Logo className={Style.logo} />
        <nav className={Style.nav}>


          {context.user?.token ?
            null :
            <div className='flex gap-2'>
              <Link to='/signin'>
                <MButton size='sm' color='white' className='text-brand-500' >
                  Sign In
                </MButton>
              </Link>

              <Link to='/signup'>
                <MButton size='sm' color='white' className='text-brand-500' >
                  Sign Up
                </MButton>
              </Link>
            </div>
          }
        </nav>
      </Content>
    </section>
  );
}