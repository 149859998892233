import { useEffect } from "react"
import { useState } from "react"

export const useTimer = (s) => {

    const [sent, setSent] = useState(false)
    const [seconds, setSeconds] = useState(s)

    useEffect(() => {
        let interval = null
        if (sent && seconds <= s && seconds >= 0) {
            interval = setInterval(() => {
                setSeconds(seconds => seconds - 1)
            }, 1000)
        }
        if (seconds === 0) {
            setSent(false)
            setSeconds(s)
        }
        return () => {
            interval && clearInterval(interval)
        }
    }, [sent, seconds, s])

    return [sent, setSent, seconds]
}