import { Permission } from 'components/lib';
import { Menu, MenuHandler, MenuList, MenuItem, Button } from "@material-tailwind/react";
import { useState } from 'react';

export const DropDown = ({ selective = true, ...props }) => {
    const [selected, setSelected] = useState(props.items.find(item => item.selected) || {})

    return (
        <Menu placement={props.placement}>
            <MenuHandler>
                <Button
                    color={props.buttonColor}
                    variant={props.buttonVariant}
                    className={props.buttonClassName}
                >
                    {selected.label || props.buttonText}
                </Button>
            </MenuHandler>

            <MenuList>
                {
                    props.items.map((item) => (
                        <Permission permission={item.permission} scope={item.scope}>
                            <MenuItem
                                key={item.label}
                                onClick={() => {
                                    item.action()
                                    if (selective) setSelected(item)
                                }}
                            >
                                {item.label}
                            </MenuItem>
                        </Permission>
                    ))
                }
            </MenuList>
        </Menu>
    )
}