/***
*
*   USER
*   shows the current user 
*   if user belongs to more than one account they can switch accounts here
*
**********/

import { useContext } from 'react';
import { AuthContext, ViewContext, HoverNav, Button } from 'components/lib';
import Style from './user.tailwind.js';
import Avatar from 'react-avatar';
import { Link } from 'react-router-dom';

export function User(props) {

  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);
  const accountName = authContext.user?.accounts?.find(x => x.id === authContext.user?.account_id)?.name;

  return (
    <div className={Style.user}>

      <Link to={`/account/profile`} className={Style.name}>
        <span className='hidden sm:block'>Welcome,</span>
        <span className='capitalize hidden sm:block'>
          {authContext.user?.name}
        </span>

        {
          authContext.user?.avatar
            ? <img
              src={authContext.user?.avatar}
              alt='avatar'
              referrerPolicy="no-referrer"
              className={Style.avatar}
            />
            :
            <Avatar
              name={authContext.user?.name}
              color={Avatar.getRandomColor('sitebase')}
              size={35}
              round={true}
              textSizeRatio={2.65}
            />
        }
      </Link>

      {authContext.user?.accounts?.length > 1 &&
        <HoverNav
          dark
          icon='user'
          label={accountName}
          className={Style.hoverNav}
          align='right' >
          {authContext.user.accounts.map(account => {

            return (
              <Button
                key={account.id}
                text={account.name}
                action={() => {

                  viewContext.setLoading(true);
                  authContext.switchAccount(account.id);

                }}
              />
            );
          }
          )}
        </HoverNav>
      }
    </div>
  )
}