import { createRoot } from 'react-dom/client';
import App from './app/app';
import { BrowserRouter } from 'react-router-dom';
import {  AuthProvider } from './app/auth';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <BrowserRouter>
        <AuthProvider>
            <App />
        </AuthProvider>
    </BrowserRouter>
);