/***
*
*   ACCOUNT
*   Index page for account functions
*
**********/

import React from 'react';
import { Animate, Grid, Card, Icon, Link, useAPI, Loader, Permission } from 'components/lib';

export function Account(props) {

  const user = useAPI('/api/user');
  const iconSize = 20;

  if (user.loading)
    return <Loader />

  return (
    <Animate>
      <Grid cols='4'>

        <Card>

          <Icon image='user' size={iconSize} />
          <h2 className={Style.subtitle}>Profile</h2>
          <div>Update your profile information</div>
          <Link url='/account/profile' className={Style.link}>
            Update
          </Link>

        </Card>

        <Card>

          <Icon image='lock' size={iconSize} />
          <h2 className={Style.subtitle}>Password</h2>
          <div>{user?.data?.['has_password'] ? 'Change your password' : 'Create a password'}</div>
          <Link url='/account/password' className={Style.link}>
            {user?.data?.['has_password'] ? 'Change' : 'Create'}
          </Link>

        </Card>

        <Card>

          <Icon image='shield' size={iconSize} />
          <h2 className={Style.subtitle}>Two-Factor Authentication</h2>
          <div>Secure your account</div>
          <Link url='/account/2fa' className={Style.link}>
            {user?.data?.['2fa_enabled'] ? 'Manage' : 'Enable'}
          </Link>

        </Card>

        <Permission permission={'admin'}>
          <Card>
            <Icon image='settings' size={iconSize} />
            <h2 className={Style.subtitle}>Integrations</h2>
            <div>Integrate your business events</div>
            <Link url='/account/integrations' className={Style.link}>
              Add
            </Link>
          </Card>
        </Permission>

        {/* <Permission permission={'owner'} scope={'account.users'}>
          <Card>
            <Icon image='users' size={iconSize} />
            <h2 className={Style.subtitle}>Users</h2>
            <div>Invite users to your account</div>
            <Link url='/account/users' className={Style.link}>
              Add users
            </Link>
          </Card>
        </Permission> */}

      </Grid>
    </Animate>
  )
}

const Style = {

  subtitle: 'font-bold mt-4',
  link: 'text-blue-500 font-semibold mt-3 inline-block'

}