import { AccessDenied } from 'components/lib';
import { Dashboard } from 'views/dashboard/dashboard';
import FAQ from 'views/dashboard/Faq';
import { Help } from 'views/dashboard/help';
import { OnboardingView } from 'views/onboarding/onboarding';

const Routes = [
  {
    path: '/',
    view: Dashboard,
    layout: 'app',
    permission: 'user',
    title: 'Campaigns'
  },
  {
    path: '/access_denied',
    view: AccessDenied,
    layout: 'app',
    permission: 'user',
    title: 'Access Denied'
  },
  {
    path: '/welcome',
    view: OnboardingView,
    layout: 'onboarding',
    permission: 'user',
    title: 'Welcome'
  },
  {
    path: '/help',
    view: Help,
    layout: 'app',
    permission: 'user',
    title: 'Get Help'
  },
  {
    path: '/faq/:id?',
    view: FAQ,
    layout: 'app',
    permission: 'user',
    title: 'FAQ'
  },
]

export default Routes;
