const ButtonStyle = {

  base: 'cursor-pointer font-open_sans_3 normal-case text-base flex justify-center',
  btn: 'text-center font-semibold rounded px-6 py-3 ease-in-out duration-300',
  big: 'md:text-lg',
  small: 'text-sm px-7',
  green: 'bg-green-500 hover:bg-green-600 ',
  red: 'bg-red-500 hover:bg-red-600 hover:border-red-500 ',
  // blue: 'bg-[#3A82F6] hover:bg-blue-600 hover:border-blue-600 ',
  orange: 'bg-orange-500 hover:bg-orange-600 hover:border-orange-600 ',
  textOnly: 'reset-all text-slate-500',
  iconButton: 'inline-block p-0 w-7 h-7',
  iconTextLeft: 'pl-12',
  iconTextRight: 'pr-12',
  iconTextOnly: 'pl-6',
  fullWidth: '!w-full',
  rounded: 'rounded-full',
  relative: 'relative',
  absolute: 'absolute',
  alignLeft: 'pl-6 pr-0',
  alignRight: 'pl-0 pr-6'
}

export default ButtonStyle;