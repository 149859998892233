import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Paginate, Table, useNavigate } from "components/lib";
import { useState } from "react";

export default function WebhooksLogs() {
    const navigate = useNavigate()

    const [offset, setOffset] = useState(0)
    const limit = 5

    const { data, isLoading } = useQuery({
        queryKey: ['integration', 'webhooks', 'logs', offset, limit],
        queryFn: () => axios.get(`/api/integration/webhooks/logs?offset=${offset}&limit=${limit}`).then(res => res.data),
    })

    const viewLog = (data) => { 
        navigate(`/account/integrations/webhook_logs/${data.id}`)
    }

    return (
        <div className="grid gap-6">
            <Paginate
                offset={offset}
                limit={limit}
                total={data?.total}
                loading={isLoading}
                onChange={x => setOffset(x)}
            />

            <Table
                loading={isLoading}
                show={['name', 'status', 'status_code', 'event', 'date_created']}
                data={data?.logs}
                badge={{
                    col: 'status', color: 'red', condition: [
                        { value: '200 Success', color: 'green' }
                    ]
                }}
                actions={{
                    custom: [
                        { icon: 'eye', action: viewLog },
                    ]
                }}
            />
        </div>
    )
}