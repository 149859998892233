import { Icon } from 'components/lib'
import React from 'react'
import {
    Popover as MPopover,
    PopoverHandler,
    PopoverContent,
    Typography,
} from "@material-tailwind/react";

export function PopOver(props) {
    const [openPopover, setOpenPopover] = React.useState(false);
    const triggers = {
        onMouseEnter: () => setOpenPopover(true),
        onMouseLeave: () => setOpenPopover(false),
    };

    return (
        <MPopover open={openPopover} handler={setOpenPopover}>
            <PopoverHandler {...triggers}>
                <button>
                    <Icon
                        image='help-circle'
                        color='blue'
                    />
                </button>
            </PopoverHandler>
            <PopoverContent {...triggers} className="z-[99999] px-3 py-2 rounded sm:max-w-[50rem]">
                <Typography variant={`paragraph`} className='text-black text-sm'>
                    {props.text}
                </Typography>
            </PopoverContent>
        </MPopover>
    )
}

