import'./angled.scss'

export const AngledBadge = (props) => {
    return (
        <div className={`base-angled-badge`}>
            {
                props.right && <span className={`angled-badge angled-badge-top-right`} data-text={props.text}></span>
            }
            {
                props.left && <span className={`angled-badge angled-badge-top-left`} data-text={props.text}></span>
            }
            {props.children}
        </div>
    )
}