import React from 'react';
import './Accordion.scss';

export const Accordion = ({  children }) => {
  return (
    <div className='accordion'>
      {children}
    </div>
  );
};

