const Style = {

  base: 'absolute left-1/2 top-1/2 w-6 h-6 -translate-y-1/2 -translate-x-1/2',
  orbit: 'animate-loading',
  over: 'fixed z-40 top-0 left-0 bg-black bg-opacity-40 h-full w-full',
  overInner: 'flex flex-col gap-6 items-center justify-center h-full',
  overLoaderBase: 'relative w-10 h-10',
  message: 'text-slate-100 text-lg capitalize'

}

export default Style;