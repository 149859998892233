import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';

export function BubbleChart(props) {

    let options = { ...props.options };
    
    options.scales.x = {
        ...options.scales.x,
        ticks: {
            ...options.scales.x.ticks,
            stepSize: 1
        }
    }

    options.scales.y = {
        ...options.scales.y,
        ticks: {
            ...options.scales.y.ticks,
            stepSize: 1,
            beginAtZero: false,
        }
    }

    return <Chart type='bubble' data={props.data} options={options} className='w-full' />

}
