import { useEffect, useState } from "react";

export const useAudio = (playerRef) => {
    const [source, setSource] = useState('');
    const [isPlaying, setIsPlaying] = useState(false);

    const player = playerRef.current;

    const EndPlayer = () => {
        player.pause();
        player.currentTime = 0;
    }

    useEffect(() => {
        if (source) {
            EndPlayer()
            player.src = source;
            player.play();
        }

    }, [source, player]);

    useEffect(() => {
        if (!player) return

        const handlePlaying = () => {
            setIsPlaying(true);
        };

        const handleEnded = () => {
            setIsPlaying(false);
        };

        player.addEventListener('playing', handlePlaying);
        player.addEventListener('pause', handleEnded)
        player.addEventListener('ended', handleEnded);

        return () => {
            player.removeEventListener('playing', handlePlaying);
            player.removeEventListener('pause', handleEnded);
            player.removeEventListener('ended', handleEnded);
        };
    }, [player]);

    return [player, setSource, EndPlayer, isPlaying];
};
