import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { AuthContext, useSocket, useView } from 'components/lib'
import React, { useContext, createContext } from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Slide, toast } from 'react-toastify';
import NotificationSound from '../../../assets/notification_sound.wav'
import { useRef } from 'react'

const Context = createContext({})

function VMCBContext({ children }) {
    const { user } = useContext(AuthContext)
    const accountId = user?.account_id || null

    const { id } = useParams()

    const view = useView()
    const audio = new Audio(NotificationSound)
    const trigger = useRef()

    const stats = useQuery({
        queryKey: ['campaign', id, 'vm_cb', 'stats'],
        queryFn: () => axios.get(`/api/campaign/${id}/vm_cb/stats`).then(res => res.data)
    })

    const campaigns = useQuery({
        queryKey: ['campaigns', 'userList', 'info'],
        queryFn: () => axios.get(`/api/campaigns/userList/info`, { id }).then(res => res.data?.data)
    })

    const notification = (text, soundOnly = false) => {
        trigger.current.click()
        if (soundOnly) return
        
        toast(text, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Slide,
        });
    }

    useEffect(() => {
        if (campaigns.isError) {
            view.handleError(campaigns.error)
        }
    }, [campaigns, view])

    const io = useSocket(process.env.REACT_APP_SMS_HANDLER_URL)

    useEffect(() => {
        const EVENT = `campaign_${accountId}_callback`
        const handleTestEvent = () => {
            stats.refetch()
            campaigns.refetch()
            notification('You got new a callback request !')
        }
        io.on(EVENT, handleTestEvent)
        return () => {
            io.off(EVENT, handleTestEvent);
        }
    }, [io, accountId, campaigns, stats])

    useEffect(() => {
        const EVENT = `campaign_${accountId}_voicemail`
        const handleTestEvent = () => {
            stats.refetch()
            campaigns.refetch()
            notification('You got new a voicemail !')
        }
        io.on(EVENT, handleTestEvent)
        return () => {
            io.off(EVENT, handleTestEvent);
        }
    }, [io, accountId, campaigns, stats])

    return (
        <Context.Provider value={{
            campaignsData: campaigns,
            stats: stats.data?.data,
            notification,
            refetchStats: () => {
                stats.refetch()
                campaigns.refetch()
            }
        }}>
            <button
                ref={trigger}
                onClick={() => audio.play()}
                className='fixed z-[-999] top-0 right-0'
            />
            {children}
        </Context.Provider>
    )
}

export const useVMCB = () => useContext(Context)

export default VMCBContext