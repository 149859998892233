import { DateTime } from 'luxon';
import emojiRegex from 'emoji-regex';

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const convert24To12 = (time) => {
  const parsedTime = DateTime.fromFormat(time, 'HH:mm');
  const hour = parsedTime.toFormat('h');
  const minutes = parsedTime.toFormat('mm');
  const ampm = parsedTime.toFormat('a');

  if (minutes === '00') {
    return `${hour}${ampm}`;
  } else {
    return `${hour}:${minutes}${ampm}`;
  }
};

export const countMessageAs = (text) => {
  const emojis = text?.match(emojiRegex()) || [];
  let chars = 160

  if (emojis.length > 0) chars = 60

  return Math.ceil(text.length / chars);
}

export const formatFullAddres = (street, city, state, zip) => {
  return `${street}, ${city}, ${state} ${zip}`;
}