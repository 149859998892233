/***
*
*   CREDIT CARD
*   Credit card component used in billing UI
*
*   PROPS
*   last_four: last 4 digits of card number
*   expires: expiry date
*   brand: card provider
*
**********/

import { CreditCardBrandSVG } from 'components/lib.js';
import Style from './creditcard.tailwind.js';

export function CreditCard(props) {

  return (
    <div className={Style.creditCard}>

      <div className={Style.brand}>
        {props.brand}
      </div>

      <div className={Style.number}>
        •••• •••• •••• {props.last_four}
      </div>

      <div className={Style.col}>
        <div className={Style.title}>
          EXPIRES
        </div>
        <div className={Style.value}>
          {props.expires}
        </div>
      </div>

      <div className={Style.col}>
        <div className={Style.title}>
          CVV
        </div>
        <div className={Style.value}>
          •••
        </div>
      </div>
    </div>
  )
}

export function InlineCreditCard({ source }) {
  return (
    <div className='flex gap-2 items-center'>
      <CreditCardBrandSVG
        brand={source.brand?.toLowerCase()}
      />
      <div className='flex gap-2 font-semibold'>
        <span>{source.brand}</span>
        <span>•••• {source.last4}</span>
      </div>
      <span className='text-gray-400'>
        Expires {source.exp_month}/{source.exp_year}
      </span>
    </div>
  )
}