import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';

export function BarChart(props) {

  let options = { ...props.options };
  
  return <Chart type='bar' data={props.data} options={options} />

}
