/***
*
*   PROFILE
*   Update the user profile or close the account
*
**********/

import React, { Fragment, useContext, useState } from 'react';
import {
  AuthContext, ViewContext, Form, Card,
  AccountNav, Button, useNavigate, Animate, useAPI, Event, Message, ModalContainer
} from 'components/lib';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import moment from 'moment-timezone'

export function Profile(props) {
  const navigate = useNavigate();

  // context
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);

  const [hasActiveCampaigns, setHasActiveCampaigns] = useState(false)

  // fetch
  const user = useAPI('/api/user');

  const checkAccountMutation = useMutation({
    mutationFn: () => axios.post('/api/user/has_active_campaigns').then(res => res.data),
  })

  async function closeAccount() {

    try {
      const res = await checkAccountMutation.mutateAsync()

      if (res.data.hasCampaigns) {
        setHasActiveCampaigns(true)
        return
      }

      viewContext.modal.show({
        title: 'Close Your Account',
        form: {},
        buttonText: 'Close Account',
        url: '/api/account',
        method: 'DELETE',
        destructive: true,
        text: 'Are you sure you want to delete your account? ' +
          'You will lose all of your data and this can not be undone.',
      }, () => {
        // destory user
        Event('closed_account');
        localStorage.clear();
        navigate('/signup');
      });

    } catch (e) {
      viewContext.handleError(e)
    }
  }

  return (
    <Fragment>
      {
        hasActiveCampaigns && (
          <ModalContainer close={() => setHasActiveCampaigns(false)} >
            <Card title={`Close Account`} className='flex flex-col'>
              <p>You currently have active campaigns linked to your account, in order to desactivate your account, please first terminate each campaign. Following the end date of your last campaign, your account will automatically become inactive after a 30-day period</p>
              <Button
                text='Continue'
                className='!w-full'
                goto='/campaigns'
                small
              />
            </Card>
          </ModalContainer>
        )
      }


      <AccountNav />
      <Animate>

        {
          !user.data?.verified ? (
            <Message
              title='Verify Your Email'
              type='error'
              text='Please verify your email to activate your account.'
              buttonLink='/signup/verify'
              buttonText='Verify Email'
            />
          )

            : !user.data?.business_verified && (
              <Message
                title='Verify Your Business'
                type='error'
                text='Please verify your business to start your journey.'
                buttonLink='/signup/verify_business'
                buttonText='Verify Business'
              />
            )
        }

        <Card title='Edit Your Profile' restrictWidth>


          {
            user.data && (
              <Form
                url='/api/user'
                method='PATCH'
                inputsClassName='grid sm:grid-cols-2 gap-2'
                buttonText='Save Profile'
                inputs={{
                  avatar: {
                    label: 'Profile Picture',
                    type: 'avatar',
                    accept: ['png', 'jpg', 'jpeg'],
                    max: 1,
                    containerClass: 'col-span-2',
                    value: user.data?.avatar
                  },
                  firstname: {
                    label: 'First Name',
                    type: 'text',
                    value: user.data?.firstname,
                    required: true,
                  },
                  lastname: {
                    label: 'Last Name',
                    type: 'text',
                    value: user.data?.lastname,
                    required: true,
                  },
                  email: {
                    label: 'Email',
                    type: 'email',
                    value: user.data?.email,
                    required: true,
                  },
                  phonenumber: {
                    label: 'Phone Number',
                    type: 'phone',
                    value: user.data?.phonenumber,
                    required: true,
                  },
                  timezone: {
                    label: 'Timezone',
                    type: 'select',
                    value: user.data?.timezone,
                    required: true,
                    options:
                      moment.tz.names()
                        .filter(t => t.startsWith('America/') || t.startsWith('US/'))
                        .map(t => ({ value: t, label: t }))
                    ,
                    containerClass: 'col-span-2'
                  }
                }}

                callback={(res) => {
                  authContext.update({ name: res.data.data.name, avatar: res.data.data.avatar });
                }}
              />
            )
          }

          {
            authContext.permission.owner && <Fragment>
              <br />
              <button
                onClick={closeAccount}
                className='underline'
              >
                Close Your Account
              </button>
            </Fragment>
          }

        </Card>
      </Animate>
    </Fragment>
  );
}
