/***
*
*   SIGN UP (user)
*   Signup form for child users
*
**********/

import React, { useContext } from 'react';
import { Animate, Row, AuthContext, Card, Form, Link } from 'components/lib';

export function SignupUser(props) {

  const context = useContext(AuthContext);
  const url = window.location.href;
  const id = url.substring(url.indexOf('?id=') + 4, url.indexOf('&email'));
  const email = url.substring(url.indexOf('&email') + 7);


  return (
    <Animate type='pop'>
      <Row title='Create Your Account'>
        <Card className='max-w-xl' center>

          <Form
            inputs={{
              firstname: {
                label: 'First Name',
                type: 'text',
                required: true,
                errorMessage: 'Please enter your first name',
                value: '',
              },
              lastname: {
                label: 'Last Name',
                type: 'text',
                required: true,
                errorMessage: 'Please enter your last name',
                value: '',
              },
              email: {
                label: 'Email',
                type: 'email',
                required: true,
                value: email
              },
              phonenumber: {
                label: 'Phone Number',
                type: 'phone',
                required: true,
                value: ''
              },
              password: {
                label: 'Password',
                type: 'password',
                required: true,
                complexPassword: true,
                containerClass: 'col-span-2',
              },
              confirm_password: {
                type: 'hidden',
                value: null,
              },
              invite_id: {
                type: 'hidden',
                value: id
              },
            }}
            url='/api/user'
            method='POST'
            redirect='/signup/verify'
            inputsClassName='grid grid-cols-2 gap-4'
            buttonText='Create Account'
            callback={context.signin}
          />

          <div className='mt-4'>
            Already registered? <Link url='/signin' text='Sign In' />
          </div>

        </Card>
      </Row>
    </Animate>
  );
}
