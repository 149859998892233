import Axios from 'axios';

// components
import { Routes, Route, useLocation } from 'react-router-dom';
import { PrivateRoute } from './auth';
import { AuthContext, Banner, Loader, View } from 'components/lib';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { hotjar } from 'react-hotjar';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { NotFound } from 'views/error/404';

import { useEffect } from 'react';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import '../css/style.css';
import { Suspense } from 'react';
import { useContext } from 'react';

const StripePromise = loadStripe(process.env.REACT_APP_STRIPE);

const routes = [
  ...require('routes/account').default,
  ...require('routes/app').default,
  ...require('routes/auth').default,
  ...require('routes/website').default,
  ...require('routes/campaign').default,
  ...require('routes/billing').default,
]

export default function App(props) {
  const location = useLocation();
  const qs = location.search;
  const auth = useContext(AuthContext)

  useEffect(() => {
    hotjar.initialize(process.env.REACT_APP_HOTJAR_ID, 6);
  }, [])

  const user = JSON.parse(localStorage.getItem('user')) || {};
  Axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL

  if (!user?.token) {
    user.token = qs.substring(qs.indexOf('?token=') + 7)
  }

  if (user?.token) {
    Axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
  }

  Axios.interceptors.response.use(
    (res) => res,
    (err) => {
      if (err.response.status === 440) {
        return auth.signout()
      }

      return Promise.reject(err)
    }
  )

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    }
  })

  return (
    <>
      {
        process.env.REACT_APP_SERVER === 'DEV' && (
          <Banner
            text='DEV SERVER'
            icon='tool'
            purple
          />
        )
      }
      <ToastContainer
        toastClassName={'!bg-gray-100 !text-gray-600 font-medium !shadow-lg'}
      />
      <Elements stripe={StripePromise}>
        <QueryClientProvider client={queryClient}>
          <Suspense fallback={<Loader />}>
            <Routes>
              {routes.map(route => {
                const RouteContext = route?.context
                if (RouteContext) {
                  return (
                    <Route
                      key={route.path}
                      path={route.path}
                      element={
                        <RouteContext>
                          {
                            route.permission
                              ? <PrivateRoute permission={route.permission} scope={route.scope}>
                                <View display={route.view} layout={route.layout} title={route.title} />
                              </PrivateRoute>
                              : <View display={route.view} layout={route.layout} title={route.title} />
                          }
                        </RouteContext>
                      }
                    />
                  )
                }
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={
                      route.permission ?
                        <PrivateRoute permission={route.permission} scope={route.scope}>
                          <View display={route.view} layout={route.layout} title={route.title} />
                        </PrivateRoute> :
                        <View display={route.view} layout={route.layout} title={route.title} />
                    }
                  />
                )
              })}

              { /* 404 */}
              <Route path='*' element={<View display={NotFound} layout='home' title='404 Not Found' />} />
            </Routes>
          </Suspense>

        </QueryClientProvider>
      </Elements>
    </>
  );
}
