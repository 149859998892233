import { ClassHelper } from "components/class"
import BannerStyle from './banner.tailwind.js'
import { Icon } from "components/lib.js"

export const Banner = (props) => {
    const style = ClassHelper(BannerStyle, {
        blue: props.blue,
        green: props.green,
        red: props.red,
        purple: props.purple,
    })

    return <div className={style}>
        {
            props.icon && (
                <Icon
                    image={props.icon}
                />
            )
        }
        <p>{props.text}</p>
        {
            props.icon && (
                <Icon
                    image={props.icon}
                />
            )
        }
    </div>
}