import { Label, Error, ClassHelper, PopOver } from 'components/lib';
import Style,{InputStyle} from './select.tailwind.js';

export function Select(props) {

  let options = props.options;
  const error = props.errorMessage || 'Please select an option';

  // set the default
  if (!props.default && options?.length) {

    // if theres no default, show a please select option
    if (options && options[0]?.value === 'unselected') options.shift(0);
    options.unshift({ value: 'unselected', label: 'Please select an option' });

  }

  function change(e) {
    let value = e ? e.target?.value : 'unselected';
    let valid = undefined;

    // validate
    valid = props.required && value === 'unselected' ? false : true;
    props.onChange(props.name, value, valid, () => e.target.selectedIndex = 0);
    props.callback && props.callback(value)

  }

  const wrapperStyle = ClassHelper(Style, {
    className: props.className,
    success: props.valid === true,
    errorWrapper: props.valid === false,
    warningWrapper: props.warning,
  });

  const selectStyle = ClassHelper(InputStyle, {
    select: true,
    error: props.valid === false,
    warning: props.warning,
    small: props.small,
  });

  return (
    <div className={Style.input}>

      {
        (props.label || props.popOver) && (
          <div className='flex items-center gap-2 mb-2'>
            {
              props.label ? <Label
                text={props.label}
                required={props.required}
                for={props.name}
                className={props.labelClass + ' !mb-0'}
                labelBold={props.labelBold}
              /> : null
            }

            {
              props.popOver && <PopOver text={props.popOver} />
            }
          </div>

        )
      }

      <div className={wrapperStyle}>

        <select
          className={selectStyle}
          defaultValue={props.default}
          value={props.value}
          onChange={e => change(e)}
          id={props.name}
          disabled={props.disabled}
        >

          {options?.map(option => {
            return (
              <option
                key={option.value}
                value={option.value}>
                {option.label}
              </option>
            );
          })}

        </select>

        {props.valid === false && <Error message={error} className={Style.message} />}

      </div>
    </div>
  );
}