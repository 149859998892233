import React from 'react'
import Orbit from './images/orbit.svg';
import Style from './loader.tailwind.js';

export function OverLoader(props) {
    const message = props.message
    return (
        <div className={Style.over}>
            <div className={Style.overInner}>
                <div className={Style.overLoaderBase}>
                    <img src={Orbit} className={Style.orbit} alt='Orbit Spinner' />
                </div>

                {
                    message && (
                        <span className={Style.message}>{message}</span>
                    )
                }
            </div>
        </div>
    )
}
