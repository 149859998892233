import { Contact } from 'views/website/contact';
import { Pricing } from 'views/website/pricing';
import { Terms } from 'views/website/terms';
import { Privacy } from 'views/website/privacy';
import { Signin } from 'views/auth/signin';
import Unsubscribe from 'views/website/unsubscribe';
import VMCVUnsubscribe from 'views/website/VMCVUnsubscribe';

const Routes = [
  {
    path: '/',
    view: Signin,
    layout: 'auth',
    title: 'Enzak'
  },
  {
    path: '/contact',
    view: Contact,
    layout: 'home',
    title: 'Contact'
  },
  {
    path: '/pricing',
    view: Pricing,
    layout: 'home',
    title: 'Pricing'
  },
  {
    path: '/privacy-policy',
    view: Privacy,
    layout: 'home',
    title: 'Privacy Policy'
  },
  {
    path: '/terms-of-service',
    view: Terms,
    layout: 'home',
    title: 'Terms & Conditions'
  },
  {
    path: '/unsubscribe/:id',
    view: Unsubscribe,
    layout: 'home',
    title: 'Unsubscribe'
  },
  {
    path: '/unsubscribe/:type/:token',
    view: VMCVUnsubscribe,
    layout: 'home',
    title: 'Unsubscribe'
  },
]

export default Routes;